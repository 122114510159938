import React, { useState } from 'react'
import { useContext } from 'react'
import { useEffect } from 'react'
import { AppContext } from '../../../Context/Context'
import { order_api } from '../../../Utils/constants'
import axios from 'axios'
import Modal from 'react-bootstrap/Modal'
import SearchBox from '../../../Utils/SearchBox'

export default function CheckoutReached() {
    const { jwt } = useContext(AppContext)
    const [datas, setDatas] = useState([])

    useEffect(() => {
        axios.get(`${order_api}/checkout_reached`, { headers: { Authorization: `Bearer ${jwt}` } })
            .then((res) => setDatas(res.data.data))
        // eslint-disable-next-line
    }, [])

    //Filter data
    const filterData = (searchTerm, data) => {
        const filteredData = data.filter((item) =>
            item.user.name.toLowerCase().includes(searchTerm.toLowerCase()) |
            item.user.email.toLowerCase().includes(searchTerm.toLowerCase())
        );
        return filteredData;
    }
    //Searchbox
    const [searchItem, setSearchItem] = useState('')
    const [filteredData, setFilteredData] = useState([])
    useEffect(() => {
        if (searchItem)
            setFilteredData(filterData(searchItem, datas))
        else
            setFilteredData([])
        // eslint-disable-next-line
    }, [searchItem])

    //Product view Modal
    const [selectedOrder, setSelectedOrder] = useState('')
    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);

    return (
        <div>
            <p className='text-2xl font-medium mb-3'>Checkout Reached</p>
            <SearchBox setSearchItem={setSearchItem} />
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Products</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        searchItem ?
                            filteredData.map((data, index) => (
                                <tr key={data.id} className='align-middle'>
                                    <th scope="row">{index+1}</th>
                                    <td>{data.user?.name}</td>
                                    <td>{data.user?.email}</td>
                                    <td className='text-blue underline cursor-pointer' onClick={() => { setSelectedOrder(data); handleShowModal() }}>View</td>
                                </tr>
                            ))
                            :
                            datas.map((data, index) => (
                                <tr key={data.id} className='align-middle'>
                                    <th scope="row">{index+1}</th>
                                    <td>{data.user?.name}</td>
                                    <td>{data.user?.email}</td>
                                    <td className='text-blue underline cursor-pointer text-left' onClick={() => { setSelectedOrder(data); handleShowModal() }}>View</td>
                                </tr>
                            ))
                    }
                </tbody>
            </table>


            <Modal show={showModal} onHide={handleCloseModal} size='lg'>
                <Modal.Header>
                    <Modal.Title className='w-full flex flex-row justify-between items-center'>
                        <p>Products</p>
                        <button onClick={handleCloseModal}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedOrder &&
                        <table className="table table-striped text-lg">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Item</th>
                                    <th scope="col">Category</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedOrder.products.map((item, index) => (
                                    <tr key={item.id}>
                                        <th scope="row">{index + 1}</th>
                                        <td>{item.name}</td>
                                        <td>{item.category.name}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    }
                </Modal.Body>
            </Modal>

        </div>
    )
}
