import React, {Fragment} from 'react'
import { Menu, Transition } from '@headlessui/react'
import { useNavigate } from 'react-router-dom'

export default function ProfileDropdown({image, email, logout, mobileView}) {

    const navigate = useNavigate()

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
      }


    return (
        <Menu as="div" className="relative md:mb-2">
            <div>
                <Menu.Button className="relative flex rounded-full bg-white text-sm focus:outline-none">
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">Open user menu</span>
                    <img
                        className="h-8 w-8 sm:h-10 sm:w-10 rounded-full"
                        src={`${image?image:'/assets/user.png'}`}
                        alt=""
                    />
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className={`absolute ${mobileView?'bottom-full':''} right-0 z-10 mt-2 w-48 origin-top-right !rounded-lg bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}>
                    <Menu.Item>
                        {({ active }) => (
                            <p
                                className={classNames(active ? 'bg-gray-100' : '', 'block px-3 py-2 text-sm text-gray-700 cursor-pointer break-words')}
                            >
                                {email}
                            </p>
                        )}
                    </Menu.Item>
                    <Menu.Item>
                        {({ active }) => (
                            <p
                                onClick={()=>navigate('/past_orders')}
                                className={classNames(active ? 'bg-gray-100' : '', 'block px-3 py-2 text-sm text-gray-700 cursor-pointer')}
                            >
                                Past Orders
                            </p>
                        )}
                    </Menu.Item>
                    <Menu.Item>
                        {({ active }) => (
                            <p
                                onClick={()=>{logout();navigate('/login')}}
                                className={classNames(active ? 'bg-gray-100' : '', 'block px-3 py-2 text-sm text-gray-700 cursor-pointer')}
                            >
                                Log out
                            </p>
                        )}
                    </Menu.Item>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}
