import React, { useEffect } from 'react'
import { useState } from 'react'
import toast from 'react-hot-toast'
import axios from 'axios'
import Modal from 'react-bootstrap/Modal'
import { useContext } from 'react'
import { AppContext } from '../../../Context/Context'
import Button from 'react-bootstrap/Button'
import { FaRegTrashAlt } from 'react-icons/fa'
import BootstrapToast from '../../BootstrapToast/BootstrapToast'
import { account_api } from '../../../Utils/constants'

export default function Users() {
    const { jwt } = useContext(AppContext)

    const [data, setData] = useState([])
    const [selectedData, setSelectedData] = useState()

    const fetchData = async () => {
        await axios.get(`${account_api}/user/all`, { headers: { Authorization: `Bearer ${jwt}` } })
            .then(function (res) {
                // console.log(res.data.data)
                setData(res.data.data)
            })
            .catch(() => toast.error("Some Error while fetching data !"))
    }

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line
    }, [])

    //Delete Modal
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const handleCloseDeleteModal = () => setShowDeleteModal(false);
    const handleCloseDeleteModalFetchData = () => { setShowDeleteModal(false); fetchData() }
    const handleShowDeleteModal = () => setShowDeleteModal(true);

    //Delete Shipping Charge
    const [loading, setLoading] = useState(false)
    const handleDeleteUser = async (data) => {
        // console.log(data)
        setLoading(true)
        await axios.delete(`${account_api}/user`,
        { 
            headers: { Authorization: `Bearer ${jwt}` }, 
            data: {user_id: data.user_id}
        })
        .then(() => {
            toast.success("Deleted User !")
            handleCloseDeleteModalFetchData()
        })
        .catch((error) => console.log(error))
        .catch(() => toast.error("Server Error !"))
        .finally(()=> setLoading(false))
    }

    return (
        <div>
            <p className='text-2xl font-medium mb-10'>Users</p>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Verified</th>
                        <th scope="col">Admin</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((data, index) => (
                            <tr key={data.user_id}>
                                <th scope="row">{index + 1}</th>
                                <td>{data.name?data.name:''}</td>
                                <td>{data.email}</td>
                                <td>{data.is_verified?'Yes':'No'}</td>
                                <td>{data.is_admin?'Yes':'No'}</td>
                                <td>
                                    <button className='btn btn-outline-danger' onClick={() => { handleShowDeleteModal(); setSelectedData(data) }}><FaRegTrashAlt /></button>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>

            <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
                <Modal.Header>
                    <Modal.Title className='w-full flex flex-row justify-between items-center'>
                        <p>Delete User</p>
                        <button onClick={handleCloseDeleteModal}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='font-sans'>Are you sure?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={handleCloseDeleteModal}>
                        No
                    </Button>
                    <Button variant="outline-danger" onClick={() => handleDeleteUser(selectedData)} disabled={loading}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            {loading && <BootstrapToast />}
        </div>
    )
}
