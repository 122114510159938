import React from 'react'
import { useContext } from 'react'
import { useEffect } from 'react'
import { AppContext } from '../../../Context/Context'
import axios from 'axios'
import { order_api } from '../../../Utils/constants'
import { useState } from 'react'
import toast from 'react-hot-toast'
import SearchBox from '../../../Utils/SearchBox'
import Modal from 'react-bootstrap/Modal'

export default function Orders() {
  const { jwt } = useContext(AppContext)

  const [orderData, setOrderData] = useState([])

  //Filter data
  const filterData = (searchTerm, data) => {
    const filteredData = data.filter((item) =>
        item.full_name.toLowerCase().includes(searchTerm.toLowerCase()) |
        item.user.email.toLowerCase().includes(searchTerm.toLowerCase()) |
        item.phone_number.toLowerCase().includes(searchTerm.toLowerCase()) |
        item.address.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return filteredData;
  }
  //Searchbox
  const [searchItem, setSearchItem] = useState('')
  const [filteredData, setFilteredData] = useState([])
  useEffect(() => {
    if (searchItem)
      setFilteredData(filterData(searchItem, orderData))
    else
      setFilteredData([])
    // eslint-disable-next-line
  }, [searchItem])

  function getDate(timestamp) {
    const date = new Date(Number(timestamp));
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
  }

  function getTime(timestamp) {
    const date = new Date(Number(timestamp));
    const hours = date.getHours() % 12 || 12; // Convert 24-hour to 12-hour format
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const period = date.getHours() < 12 ? 'AM' : 'PM';
    return `${hours}:${minutes} ${period}`;
  }

  const fetchData = () => {
    axios.get(`${order_api}/all_orders`, { headers: { Authorization: `Bearer ${jwt}` } })
      .then((res) => setOrderData(res.data.data))
      .catch((err) => toast.error("Could not fetch data !"))
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line
  }, [])

  //Bill Modal
  const [selectedOrder, setSelectedOrder] = useState('')
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const [loading, setLoading] = useState(false)
  const updateDelivered = (order) => {
    setLoading(true)
    axios.patch(`${order_api}`, {
      id: order.id,
      is_delivered: !order.isDelivered
    },
      { headers: { Authorization: `Bearer ${jwt}` } })
      .then(() => {
        toast.success("Updated !")
        fetchData()
      })
      .catch((e) => toast.error("Server Error !"))
      .finally(() => setLoading(false))
  }

  const updatePaymentReceived = (order) => {
    setLoading(true)
    axios.patch(`${order_api}`, {
      id: order.id,
      is_payment_received: !order.isPaymentReceived
    },
      { headers: { Authorization: `Bearer ${jwt}` } })
      .then(() => {
        toast.success("Updated !")
        fetchData()
      })
      .catch((e) => toast.error("Server Error !"))
      .finally(() => setLoading(false))
  }

  return (
    <div>
      <p className='text-2xl font-medium mb-3'>Orders</p>
      <SearchBox setSearchItem={setSearchItem} />
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">DateTime</th>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Phone</th>
            <th scope="col">Address</th>
            <th scope="col">Bill</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {
            searchItem ?
              filteredData.map((data, index) => (
                <tr key={data.id} className='align-middle'>
                  <th scope="row">{index+1}</th>
                  <td className='flex flex-col justify-center'>
                    <p>{getDate(data.order_date)}</p>
                    <p>{getTime(data.order_date)}</p>
                  </td>
                  <td>{data.full_name}</td>
                  <td>{data.user?.email}</td>
                  <td>{data.phone_number}</td>
                  <td>{data.address}</td>
                  <td className='text-blue underline cursor-pointer' onClick={() => { setSelectedOrder(data); handleShowModal() }}>View</td>
                  <td>
                    <input type='checkbox' onChange={() => updateDelivered(data)} checked={data.isDelivered} disabled={loading} />&nbsp;Delivered<br />
                    <input type='checkbox' onChange={() => updatePaymentReceived(data)} checked={data.isPaymentReceived} disabled={loading} />&nbsp;Payment Received<br />
                  </td>
                </tr>
              ))
              :
              orderData.map((data, index) => (
                <tr key={data.id} className='align-middle'>
                  <th scope="row">{index+1}</th>
                  <td className='flex flex-col justify-center'>
                    <p>{getDate(data.order_date)}</p>
                    <p>{getTime(data.order_date)}</p>
                  </td>
                  <td>{data.full_name}</td>
                  <td>{data.user?.email}</td>
                  <td>{data.phone_number}</td>
                  <td>{data.address}</td>
                  <td className='text-blue underline cursor-pointer' onClick={() => { setSelectedOrder(data); handleShowModal() }}>View</td>
                  <td>
                    <input type='checkbox' onChange={() => updateDelivered(data)} checked={data.isDelivered} disabled={loading} />&nbsp;Delivered<br />
                    <input type='checkbox' onChange={() => updatePaymentReceived(data)} checked={data.isPaymentReceived} disabled={loading} />&nbsp;Payment Received<br />
                  </td>
                </tr>
              ))
          }
        </tbody>
      </table>


      <Modal show={showModal} onHide={handleCloseModal} size='lg'>
        <Modal.Header>
          <Modal.Title className='w-full flex flex-row justify-between items-center'>
            <p>Bill</p>
            <button onClick={handleCloseModal}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
              </svg>
            </button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedOrder && 
          <div className='font-sans flex flex-col gap-1 px-3 py-1'>
            <p className='text-xl text-right'>
              <span className='font-semibold'>Order Date :</span>&nbsp;
              {getDate(selectedOrder.order_date)}
            </p>
            <br />
            <p className='text-2xl underline font-semibold my-1'>Customer Information</p>
            <p className='text-xl'>
              <span className='font-semibold'>Name :</span>&nbsp;
              {selectedOrder.full_name}
            </p>
            <p className='text-xl'>
              <span className='font-semibold'>Email :</span>&nbsp;
              {selectedOrder.user.email}
            </p>
            <p className='text-xl'>
              <span className='font-semibold'>Phone :</span>&nbsp;
              {selectedOrder.phone_number}
            </p>
            <p className='text-xl'>
              <span className='font-semibold'>Address :</span>&nbsp;
              {selectedOrder.address}
            </p>
            <p className='text-xl'>
              <span className='font-semibold'>Shipping Address :</span>&nbsp;
              {selectedOrder.shippingCharge.location}
            </p>
            <br />
            <p className='text-2xl underline font-semibold my-1'>Order Summary</p>
            <table className="table table-striped text-lg">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Item</th>
                  <th scope="col">Price</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">Total</th>
                </tr>
              </thead>
              <tbody>
                {selectedOrder.items.map((item,index)=>(
                  <tr key={item.product.id}>
                  <th scope="row">{index+1}</th>
                  <td>{item.product.name}</td>
                  <td>{item.product.discounted_price}</td>
                  <td>{item.quantity}</td>
                  <td>{item.total_discounted_price}</td>
                </tr>
                ))}
                <tr>
                  <th className='text-right' colSpan="4">Shipping Charge&nbsp;</th>
                  <td>{selectedOrder.shippingCharge.charge}</td>
                </tr>
                <tr>
                  <th className='text-right' colSpan="4">Total Amount&nbsp;</th>
                  <th>{selectedOrder.total_discounted_price + selectedOrder.shippingCharge.charge}</th>
                </tr>
              </tbody>
            </table>
          </div>
}
        </Modal.Body>
      </Modal>

    </div>
  )
}
