import React from 'react'
import './Rating.css'

export default function Rating({rating, showRate=true}) {
    
    const starsStyle = {
        width: `${rating*20}%`,
    };

    return (
        <div>
            <div className="card-star-rating">
                <div className="card-star-rating-upper" style={starsStyle}>
                    <span>★</span>
                    <span>★</span>
                    <span>★</span>
                    <span>★</span>
                    <span>★</span>
                </div>
                <div className="card-star-rating-lower">
                    <span>★</span>
                    <span>★</span>
                    <span>★</span>
                    <span>★</span>
                    <span>★</span>
                </div>
            </div>
            {showRate && <span className="card-rating-text fs-5 satoshi-regular">&ensp;{rating}</span>}
        </div>
    )
}
