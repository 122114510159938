import React, { useContext } from 'react'
import './ProductCardv2.css'
import { AiFillHeart } from 'react-icons/ai'
// import { useState } from 'react'
import { motion } from 'framer-motion'
// import CartQuantityToggle from '../CartQuantityToggle/CartQuantityToggle'
import { Link } from 'react-router-dom'
import AddToCart from '../AddToCart'
import AddToWishlist from '../AddToWishlist'
import { AppContext } from '../../Context/Context'
import { FaMinus, FaPlus } from "react-icons/fa";

export default function ProductCard({ item, featuredShowCase=false }) {
    // const [quantity, setQuantity] = useState(1)
    // const setDecrease = () => {
    //     quantity > 1 ? setQuantity(quantity - 1) : setQuantity(1);
    // }
    // const setIncrease = () => {
    //     setQuantity(quantity + 1);
    // }

    //Fetch cart and wishlist
    const { cart, wishlist, increaseQuantity, decreaseQuantity } = useContext(AppContext)
    const cartPresent = cart.find((i) => i.id === item.id)
    if (item)
        return (
            <div className="">
                <div className={`${featuredShowCase?'w-[55vw]':'w-[42vw]'} md:w-[20rem] mx-auto`}>
                    <div className="product-card rounded-3xl overflow-hidden">
                        <div className='relative'>
                            <Link to={`/products/${item.slug}`}>
                                <div className="bg-cover h-32 md:h-56 p-4 bg-center" style={{ backgroundImage: `url(${item.images[0]})` }} />
                            </Link>
                            <div className="absolute top-1 right-1 md:top-2 md:right-2">
                                <AddToWishlist item={item} quantity={1} rating={item.rating}>
                                    <span className={`p-1 md:!p-2 wishlist ${wishlist.find((i) => i.id === item.id) ? 'bg-[#F4F5F7]' : 'bg-theme-red'} hover:scale-110 cursor-pointer`}>
                                        <AiFillHeart className={` ${wishlist.find((i) => i.id === item.id) ? 'text-theme-red' : 'text-[#F4F5F7]'} text-lg md:text-2xl`} />
                                    </span>
                                </AddToWishlist>
                            </div>
                        </div>
                        <div className="p-1 md:!p-3 text-center">
                            <Link to={`/products/${item.slug}`}>
                                <h5 className="text-base md:text-xl xl:text-2xl font-bold ">{item.title}</h5>
                            </Link>
                            <div className="d-flex flex-column mt-1 md:!mt-2">
                                <div className='flex flex-row gap-2 lg:gap-3 items-end justify-center'>
                                    {item.actualPrice !== item.price ? <span className="satoshi-regular text-xs md:text-base font-medium opacity-50 line-through">Rs. {item.actualPrice}</span> : <></>}
                                    <span className="satoshi-regular font-bold text-sm md:text-xl">Rs. {item.price}</span>
                                </div>
                                <span className="old-price text-center text-xs md:text-sm mb-2 lg:mb-1">per {item.unit}</span>
                                {/* <div className='grid grid-cols-2'>
                                    <CartQuantityToggle
                                        quantity={quantity}
                                        setDecrease={setDecrease}
                                        setIncrease={setIncrease}
                                    />
                                </div> */}
                            </div>
                        </div>


                        {/* <div className="flex p-4 border-t border-gray-300 text-gray-700">
                            </div> */}
                        <div className="px-1 md:!px-4 pt-1 pb-2 border-t border-gray-300 bg-transparent">
                            <div className="flex flex-row justify-between items-center">
                                <div className='flex flex-col justify-center items-center'>
                                    {/* <AiFillStar style={{ color: '#FFD300' }} className="fs-3" />
                                    <span className="text-base satoshi-regular">{item.rating}</span> */}
                                </div>
                                <div className='flex flex-row gap-2 items-center'>
                                    {cartPresent ?
                                        <>
                                            <Link to="/cart">
                                                <motion.button
                                                    whileHover={{ scale: 1.1 }}
                                                    whileTap={{ scale: 0.9 }}
                                                    className="border-2 rounded-lg p-1 text-white !border-theme-red bg-theme-red w-fit mt-2 mb-1 text-xs md:!text-base"
                                                >
                                                    Buy Now
                                                </motion.button>
                                            </Link>
                                            <div className='border-2 rounded-lg p-1 text-white bg-theme-red !border-theme-red w-fit h-full items-center justify-center flex mt-2 mb-1'>
                                                <button className='px-1' onClick={() => decreaseQuantity(cartPresent.id)}>
                                                    <FaMinus className='text-[0.5rem] md:text-xs' />
                                                </button>
                                                <span className='px-1 md:px-2 text-xs md:text-base'>{cartPresent.quantity}</span>
                                                <button className='px-1' onClick={() => increaseQuantity(cartPresent.id)}>
                                                    <FaPlus className='text-[0.5rem] md:text-xs' />
                                                </button>
                                            </div>
                                        </>

                                        :
                                        <>
                                            <AddToCart item={item} quantity={1}>
                                                <Link to="/cart">
                                                    <motion.button
                                                        whileHover={{ scale: 1.1 }}
                                                        whileTap={{ scale: 0.9 }}
                                                        className="border-2 rounded-lg p-1 text-white !border-theme-red bg-theme-red w-fit mt-2 mb-1 text-xs md:!text-base"
                                                    >
                                                        Buy Now
                                                    </motion.button>
                                                </Link>
                                            </AddToCart>
                                            <AddToCart item={item} quantity={1}>

                                                <motion.button
                                                    whileHover={{ scale: 1.1 }}
                                                    whileTap={{ scale: 0.9 }}
                                                    className="border-2 rounded-lg p-1 text-theme-red !border-theme-red w-fit mt-2 mb-1"
                                                >
                                                    <FaPlus />
                                                </motion.button>

                                            </AddToCart>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
}
