import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useContext } from 'react';
import { AppContext } from '../../Context/Context'
import Loader from '../Loader/Loader'
import { RightArrow } from "../../Utils/Arrow";
import ProductCard from '../ProductCard/ProductCard';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';

export default function FeaturedProduct() {

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const { products, updateSelectedCategoryByText } = useContext(AppContext)

    const [featuredProduct, setFeaturedProduct] = useState([])
    
    useEffect(()=>{
        updateSelectedCategoryByText("all")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(()=>{
        if(products.length)
            setFeaturedProduct(products.filter(product =>{return product.featured}))
    },[products, setFeaturedProduct])

    return (
        <section className='py-10 pt-5 bg-theme-pink'>
            <div className='mb-3 px-[18px] flex flex-col gap-3 md:flex-row md:justify-between md:items-center md:px-24 md:mb-5'>
                <p className="text-2xl md:text-4xl fw-bold">Featured Items</p>
                <Link to="/products">
                    <div className='flex flex-row justify-end md:justify-normal gap-2 cursor-pointer'>
                        <p className="text-xl fw-bold">View All</p>
                        <RightArrow />
                    </div>
                </Link>
            </div>
            {!products ? <Loader /> :
                <div className='relative'>
                    <Carousel responsive={responsive} ssr={true}>
                        {featuredProduct.map(product => (
                            <div key={product.slug} className='my-5'><ProductCard item={product} featuredShowCase={true}/></div>
                        ))}
                    </Carousel>
                </div>
            }
        </section>
    )
}
