import React from 'react'
import FeaturedProduct from '../../Components/FeaturedProduct/FeaturedProduct'
import { useEffect } from 'react'
import { useState } from 'react'
import axios from 'axios'
import { home_api } from '../../Utils/constants'
import TopBannerCarousel from '../../Components/TopBannerCarousel/TopBannerCarousel'
import OfferCard from '../../Components/OfferCard/OfferCard'
import Categories from '../../Components/Categories/Categories'
import Testimonials from '../../Components/Testimonials/Testimonials'

export default function Home() {

  const [bannerImage, setBannerImage] = useState([])
  const [offerCard, setOfferCard] = useState()

  useEffect(() => {
    axios.get(`${home_api}`)
      .then(function (response) {
        if (response.data) {
          // console.log(response.data)
          setBannerImage(response.data.data.carousel_images)
          setOfferCard(response.data.data.offer_image)
        }
      })
  }, [])

  return (
    <div>
      {offerCard && offerCard.description ?
        <div className='marquee text-xs sm:text-sm md:text-md xl:text-xl'>
          <p className=''>{offerCard.description}</p>
        </div>
        :
        <></>
      }
      <div className='bg-gray-400 max-h-fit'>
        <TopBannerCarousel images={bannerImage} />
      </div>

      <FeaturedProduct />


      {offerCard && <OfferCard offerCard={offerCard} />}

      <Testimonials />

      <Categories />

    </div>
  )
}
