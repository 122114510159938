import React from 'react'
import AddCategoryForm from './AddCategoryForm';


export default function AddCategory() {
    return (
        <>
            <p className='text-2xl font-medium mb-10'>Add Category</p>
            <AddCategoryForm type="add"/>
        </>
    )
}
