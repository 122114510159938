import { FaCircleArrowLeft, FaCircleArrowRight } from "react-icons/fa6"

export function RightArrow({size}) {
    return (
        <FaCircleArrowRight className={`${size?`text-${size}`:'text-3xl'} hover:shadow-sm cursor-pointer text-theme-orange-dark`} />
    )
}

export function LeftArrow({size}) {
    return (
        <FaCircleArrowLeft className={`${size?`text-${size}`:'text-3xl'} hover:shadow-sm cursor-pointer text-theme-orange-dark`} />
    )
}