import React from 'react'
import EmailForm from './EmailForm'
import { useState } from 'react'
import ChangePassword from './ChangePassword'
import PasswordChanged from './PasswordChanged'

export default function ResetPassword() {

    const [step, setStep] = useState(1)

    return (
        <section class="flex flex-col md:flex-row min-h-screen items-center">

            <div class="bg-indigo-600 hidden lg:block w-full md:w-1/2 xl:w-2/3 min-h-screen">
                <img src="/assets/valentines-day.jpg" alt="" class="w-full h-full object-cover" />
            </div>

            <div class="bg-white w-full md:max-w-md lg:max-w-full md:mx-auto md:mx-0 md:w-1/2 xl:w-1/3 min-h-screen px-6 lg:px-16 xl:px-12
        flex justify-center">

                {/* <EmailForm /> */}
                {/* <ChangePassword/> */}
                {step === 1 ? <EmailForm setStep={setStep} /> : (step === 2 ? <ChangePassword setStep={setStep} /> : <PasswordChanged />)}
{/* <PasswordChanged/> */}
            </div>

        </section>
    )
}
