const Reducer = (state, action) => {

    //Handle user
    if (action.type === 'STORE_JWT') {
        return ({ ...state, jwt: action.payload, isAuthorized: true })
    }
    if (action.type === 'GET_USER') {
        return ({ ...state, user: action.payload, isAuthorized: true})
    }
    if (action.type === 'LOGOUT') {
        return ({ ...state, jwt: '', user:{}, isAuthorized: false })
    }
    
    /*Fetch*/
    if (action.type === 'GET_PRODUCTS') {
        return { ...state, products: action.payload, filter_products: action.payload }
    }
    if (action.type === 'FETCH_SEARCH_PRODUCTS') {
        return ({ ...state, products: action.payload })
    }
    if (action.type === 'GET_CATEGORIES') {
        return { ...state, categories: action.payload }
    }

    //Display Cart Item from local storage cart
    if (action.type === 'DISPLAY_CART_ITEMS') {
        const mergedData = state.cart.map((cartItem) => {
            const productItem = state.products.find((item) => item.id === cartItem.id);
            return productItem ? { ...cartItem, ...productItem } : null;
          }).filter((item) => item !== null);
        return { ...state, cartItems: mergedData }
    }

    /*SORT*/
    if (action.type === 'UPDATE_SORT') {
        return { ...state, sort: action.payload }
    }
    /*SORTING*/
    if (action.type === 'SORT_PRODUCTS') {
        const { sort, products } = state;
        let tempProducts = [...products];
        /*SORTING PRICE LOWEST TO HIGHEST*/
        if (sort === 'price') {
            tempProducts = tempProducts.sort((a, b) => a.price - b.price)
        }
        /*SORTING PRICE HIGHEST TO LOWEST*/
        if (sort === '-price') {
            tempProducts = tempProducts.sort((a, b) => b.price - a.price)
        }
        /*SORTING NAME A TO Z*/
        if (sort === 'title') {
            tempProducts = tempProducts.sort((a, b) => {
                return a.title.localeCompare(b.title)
            })
        }
        /*SORTING NAME Z TO A*/
        if (sort === '-title') {
            tempProducts = tempProducts.sort((a, b) => {
                return b.title.localeCompare(a.title)
            })
        }
        return { ...state, products: tempProducts }
    }

    /* Show products by category */
    if (action.type === 'UPDATE_SELECT') {
        return { ...state, selectedCategory: action.payload }
    }

    if (action.type === 'LOAD_CATEGORIES') {
        const { selectedCategory, filter_products } = state
        let tempProducts = [...filter_products]
        if (selectedCategory === 'all') {
            return { ...state, products: filter_products }
        }
        if (selectedCategory) {
            tempProducts = tempProducts.filter((item => {
                return item.category.startsWith(selectedCategory)
            }))
        }
        return { ...state, products: tempProducts }
    }

    /*SEARCHING*/
    if (action.type === 'SEARCH_BY_NAME') {
        return { ...state, searchText: action.payload, }
    }

    /*ADDING_TO_CART*/
    if (action.type === 'ADD_TO_CART') {
        const { id, quantity } = action.payload;
        /*CHECKING FOR SAME ID*/
        const tempItems = state.cart.find((i) => i.id === id)

        /*IF ID MATCHES*/
        if (tempItems) {
            const tempCart = state.cart.map(cartItem => {
                if (cartItem.id === id) {
                    let newQuantity = cartItem.quantity + quantity
                    return { ...cartItem, quantity: newQuantity }
                } else {
                    return cartItem
                }
            })
            //console.log(tempItems);
            return { ...state, cart: tempCart }
        }
        /*IF ID DOESN'T MATCHES; RETURNS REGULAR PAYLOAD*/
        else {
            const newItems = {
                id,
                quantity
            }
            return { ...state, cart: [...state.cart, newItems] }
        }
        // return { ...state }
    }

    /*REMOVE_PRODUCT*/
    if (action.type === 'REMOVE_PRODUCT') {
        const tempProduct = state.cart.filter(item => item.id !== action.payload)
        return { ...state, cart: tempProduct }
    }

    /*CLEAR_CART*/
    if (action.type === 'CLEAR_CART') {
        return { ...state, cart: [] }
    }
    /*TOGGLE_AMOUNT*/
    if(action.type === 'INCREASE_QUANTITY'){
        const tempKart = state.cart.map((items)=>{
            if(items.id === action.payload){
                let newAmount = items.quantity + 1;
                return {...items, quantity: newAmount}
            }else{
                return {...items}
            }
        })
        return {...state, cart:tempKart}
    }
    if(action.type === 'DECREASE_QUANTITY'){
        const tempKart = state.cart.map((items)=>{
            if(items.id === action.payload){
                let newAmount = items.quantity - 1;
                return {...items, quantity: newAmount}
            }else{
                return {...items}
            }
        }).filter(items => items.quantity !== 0 )
        return {...state, cart:tempKart}
    }

    /*COUNT_CART_TOTAL; total_items && total_amount*/
    if (action.type === 'COUNT_CART_TOTAL') {
        const { total_items, total_amount } = state.cartItems.reduce((total, cartItem) => {
            const { quantity, price } = cartItem
            total.total_items += quantity;
            total.total_amount += price * quantity
            return total
        }, { total_items: 0, total_amount: 0 })
        return { ...state, total_items, total_amount }
    }
    
    /*EMPTY_CART_AFTER_PAYMENT*/
    if (action.type === 'CLEAR_CART_AFTER_PAYMENT') {
        return { ...state, cart: [] }
    }

    /*ADDING_TO_WISHLIST*/
    if (action.type === 'ADD_TO_WISHLIST') {
        const { id, title, images, price, rating } = action.payload;
        /*CHECKING FOR SAME ID*/
        const tempItems = state.wishlist.find((i) => i.id === id)

        /*IF ID MATCHES then delete it from wishlist*/
        if (tempItems) {
            // console.log(tempItems);
            const tempWishlist = state.wishlist.filter(item => item.id !== id)
            // console.log(tempWishlist);
            return { ...state, wishlist: tempWishlist }
        }
        /*IF ID DOESN'T MATCHES; RETURNS REGULAR PAYLOAD*/
        else {
            const newItems = {
                id,
                title,
                images,
                price,
                rating
            }
            return { ...state, wishlist: [...state.wishlist, newItems] }
        }
        // return { ...state }
    }

    /*REMOVE_PRODUCT*/
    if (action.type === 'REMOVE_WISHLIST_PRODUCT') {
        const tempProduct = state.wishlist.filter(item => item.id !== action.payload)
        return { ...state, wishlist: tempProduct }
    }
    return state
}

export default Reducer