import React from 'react'
import ImageUploading from 'react-images-uploading';
import { FaDownload } from "react-icons/fa";
import { CiCircleRemove } from "react-icons/ci";
import toast from 'react-hot-toast';
import Form from 'react-bootstrap/Form';
import axios from 'axios'
import { useState } from 'react';
import { base_api } from '../../../Utils/constants';
import { deleteFirebaseImage, uploadFirebaseImage } from '../../../Utils/FirebaseStorage';
import { useContext } from 'react';
import { AppContext } from "../../../Context/Context"

export default function UpdateHomePageImages({data, closeModal}) {
    const {jwt} = useContext(AppContext)

    const [updatedData, setUpdatedData] = useState({
        id: data.id,
        offer_image: data.offer_image?data.offer_image.image_url:null,
        offer_image_description: data.offer_image?data.offer_image.description:'',
        logo: data.logo?data.logo:null,
        carousel_images: data.carousel_images?data.carousel_images:null
    })

    //Offer Image Banner
    const [carouselImages, setCarouselImages] = useState([])
    const onCarouselImageUpload = async (imageList) => {
        setCarouselImages(imageList);
    };

    //Offer Image Banner
    const [offerImages, setOfferImages] = useState([])
    const onOfferImageUpload = async (imageList) => {
        setOfferImages(imageList);
    };

    //Logo
    const [logo, setLogo] = useState([])
    const onLogoUpload = async (imageList) => {
        setLogo(imageList);
    };

    //Deleted iamges
    const [deletedImageUrl, setDeletedImageUrl] = useState([])
    const onStorageCarouselImageRemove = (e,image) => {
        e.stopPropagation()
        setDeletedImageUrl([...deletedImageUrl,image])
        setUpdatedData({...updatedData,carousel_images:updatedData.carousel_images.filter(data => data !== image)})
    }

    const onStorageOfferImageRemove = (e, image) => {
        e.stopPropagation()
        setDeletedImageUrl([...deletedImageUrl,image])
        setUpdatedData({...updatedData,offer_image:updatedData.offer_image.filter(data => data !== image)})
    }

    const [loading, setLoading] = useState(false)
    const handleSubmit = async(e) => {
        e.preventDefault()
        setLoading(true)
        if(deletedImageUrl.length){
            deletedImageUrl.map(async(image) => await deleteFirebaseImage(image,"homepageImages"))
            setDeletedImageUrl([])
        }
        // let offer_image_url = offerImages? await uploadFirebaseImage(offerImages, "homepageImages") : null
        let logo_image_url = logo? await uploadFirebaseImage(logo, "homepageImages") : null
        let offer_image_url = []
        if(updatedData.offer_image)
            offer_image_url = updatedData.offer_image
        if(carouselImages){
            let new_offer_image_url = await uploadFirebaseImage(offerImages, "homepageImages")
            console.log("new"+new_offer_image_url)
            if(offer_image_url.length)
                new_offer_image_url.map(image=>offer_image_url.push(image))
            else
                offer_image_url = new_offer_image_url
        }
        let carousel_image_url = []
        if(updatedData.carousel_images)
            carousel_image_url = updatedData.carousel_images
        if(carouselImages){
            let new_carousel_image_url = await uploadFirebaseImage(carouselImages, "homepageImages")
            console.log("new"+new_carousel_image_url)
            if(carousel_image_url.length)
                new_carousel_image_url.map(image=>carousel_image_url.push(image))
            else
                carousel_image_url = new_carousel_image_url
        }
        let dataToSend = {
            id: updatedData.id,
            logo: updatedData.logo?updatedData.logo:logo_image_url.length?logo_image_url[0]:null,
            offer_image: (updatedData.offer_image || offer_image_url.length)?{description:updatedData.offer_image_description, image_url:offer_image_url}:null,
            carousel_images: carousel_image_url.length?carousel_image_url:null
        }
        console.log(dataToSend)
        axios.patch(`${base_api}/home`,dataToSend,{headers: {Authorization: `Bearer ${jwt}`}})
        .then(()=>{
            toast.success("Updated Homepage information !")
            closeModal()
        })
        .catch((e)=>console.log(e))
        .finally(()=>setLoading(false))
    }

    return (
        <Form className={`font-sans px-4 ${loading ? 'opacity-50' : ''}`}>

            {/* Carousel Images */}
            <Form.Group className="mb-4 w-full">
                <Form.Label className='font-medium'>Carousel Banner Image</Form.Label>
                <ImageUploading
                    value={carouselImages}
                    onChange={onCarouselImageUpload}
                    multiple
                    dataURLKey="data_url"
                >
                    {({
                        imageList,
                        onImageUpload,
                        onImageRemove,
                        isDragging,
                        dragProps,
                        errors
                    }) => (
                        // write your building UI
                        <div className="upload__image-wrapper">
                            <div
                                style={isDragging ? { opacity: '0.5' } : undefined}
                                className={`w-full h-60 bg-[#F2F2F2] ring-1 ring-black rounded-xl flex ${imageList.length || updatedData.carousel_images ? 'flex-wrap' : 'justify-center items-center flex-col'} gap-3`}
                                onClick={onImageUpload}
                                {...dragProps}
                            >
                                {!imageList.length && !updatedData.carousel_images ?
                                    <>
                                        <FaDownload className='w-10 h-10' />
                                        <p>Click or Drop here</p>
                                    </>
                                    :
                                    <>
                                        {
                                            updatedData.carousel_images &&
                                            updatedData.carousel_images.map((image, index) => (
                                                <div key={index} className="flex flex-col p-2 gap-2">
                                                    <img src={image} alt="" width="100" className='rounded-xl' />
                                                    <div className="flex justify-center w-full">
                                                        <button onClick={(e) => onStorageCarouselImageRemove(e,image)} className='w-full flex justify-center'>
                                                            <CiCircleRemove className='w-6 h-6' />
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                        {imageList.map((image, index) => (
                                            <div key={index} className="flex flex-col p-2 gap-2">
                                                <img src={image['data_url']} alt="" width="100" className='rounded-xl' />
                                                <div className="flex justify-center w-full">
                                                    <button onClick={(e) => { e.stopPropagation(); onImageRemove(index) }} className='w-full flex justify-center'>
                                                        <CiCircleRemove className='w-6 h-6' />
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                }
                            </div>
                            {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
                            {errors && <div className='hidden'>
                                {errors.maxNumber && toast.error('Only 1 image allowed !', { id: 'maxnumberofferimage' })}
                                {errors.acceptType && toast.error("Only images can be uploaded !", { id: 'typeofferimage' })}
                                {errors.maxFileSize && toast.error("File size too large !", { id: 'filesizeofferimage' })}
                            </div>
                            }
                        </div>
                    )}
                </ImageUploading>
            </Form.Group>
            
            <Form.Group className="mb-4 w-full">
                <Form.Label className='font-medium'>Offer Banner Image</Form.Label>
                <ImageUploading
                    value={offerImages}
                    onChange={onOfferImageUpload}
                    multiple
                    dataURLKey="data_url"
                >
                    {({
                        imageList,
                        onImageUpload,
                        onImageRemove,
                        isDragging,
                        dragProps,
                        errors
                    }) => (
                        // write your building UI
                        <div className="upload__image-wrapper">
                            <div
                                style={isDragging ? { opacity: '0.5' } : undefined}
                                className={`w-full h-60 bg-[#F2F2F2] ring-1 ring-black rounded-xl flex ${imageList.length || updatedData.offer_image ? 'flex-wrap' : 'justify-center items-center flex-col'} gap-3`}
                                onClick={onImageUpload}
                                {...dragProps}
                            >
                                {!imageList.length && !updatedData.offer_image ?
                                    <>
                                        <FaDownload className='w-10 h-10' />
                                        <p>Click or Drop here</p>
                                    </>
                                    :
                                    <>
                                        {
                                            updatedData.offer_image &&
                                            updatedData.offer_image.map((image, index) => (
                                                <div key={index} className="flex flex-col p-2 gap-2">
                                                    <img src={image} alt="" width="100" className='rounded-xl' />
                                                    <div className="flex justify-center w-full">
                                                        <button onClick={(e) => onStorageOfferImageRemove(e,image)} className='w-full flex justify-center'>
                                                            <CiCircleRemove className='w-6 h-6' />
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                        {imageList.map((image, index) => (
                                            <div key={index} className="flex flex-col p-2 gap-2">
                                                <img src={image['data_url']} alt="" width="100" className='rounded-xl' />
                                                <div className="flex justify-center w-full">
                                                    <button onClick={(e) => { e.stopPropagation(); onImageRemove(index) }} className='w-full flex justify-center'>
                                                        <CiCircleRemove className='w-6 h-6' />
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                }
                            </div>
                            {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
                            {errors && <div className='hidden'>
                                {errors.maxNumber && toast.error('Only 1 image allowed !', { id: 'maxnumberofferimage' })}
                                {errors.acceptType && toast.error("Only images can be uploaded !", { id: 'typeofferimage' })}
                                {errors.maxFileSize && toast.error("File size too large !", { id: 'filesizeofferimage' })}
                            </div>
                            }
                        </div>
                    )}
                </ImageUploading>
            </Form.Group>
            <Form.Group className="mb-4">
                <Form.Label className='font-medium'>Description</Form.Label>
                <Form.Control placeholder="Enter Description for Offer Image" name="description" onChange={(e)=>setUpdatedData({...updatedData, offer_image_description:e.target.value})} value={updatedData.offer_image_description?updatedData.offer_image_description:''} />
            </Form.Group>

            <hr className='my-5' />

            <Form.Group className="mb-4 w-full">
                <Form.Label className='font-medium'>Logo</Form.Label>
                <ImageUploading
                    value={logo}
                    onChange={onLogoUpload}
                    maxNumber={1}
                    dataURLKey="data_url"
                >
                    {({
                        imageList,
                        onImageUpload,
                        onImageRemove,
                        isDragging,
                        dragProps,
                        errors
                    }) => (
                        // write your building UI
                        <div className="upload__image-wrapper">
                            <div
                                style={isDragging ? { opacity: '0.5' } : undefined}
                                className={`w-full h-48 bg-[#F2F2F2] ring-1 ring-black rounded-xl flex ${imageList.length || updatedData.logo ? 'flex-wrap' : 'justify-center items-center flex-col'} gap-3`}
                                onClick={updatedData.logo ? void (0) : onImageUpload}
                                {...dragProps}
                            >
                                {!imageList.length && !updatedData.logo ?
                                    <>
                                        <FaDownload className='w-10 h-10' />
                                        <p>Click or Drop here</p>
                                    </>
                                    :
                                    <>
                                        {
                                            updatedData.logo &&
                                            <div className="flex flex-col p-2 gap-2">
                                                <img src={updatedData.logo} alt="" width="100" className='rounded-xl' />
                                                <div className="flex justify-center w-full">
                                                    <button onClick={(e) => { e.stopPropagation(); setDeletedImageUrl([...deletedImageUrl, updatedData.logo]); setUpdatedData({...updatedData, logo:null}) }} className='w-full flex justify-center'>
                                                        <CiCircleRemove className='w-6 h-6' />
                                                    </button>
                                                </div>
                                            </div>
                                        }
                                        {imageList.map((image, index) => (
                                            <div key={index} className="flex flex-col p-2 gap-2">
                                                <img src={image['data_url']} alt="" width="100" className='rounded-xl' />
                                                <div className="flex justify-center w-full">
                                                    <button onClick={(e) => { e.stopPropagation(); onImageRemove(index) }} className='w-full flex justify-center'>
                                                        <CiCircleRemove className='w-6 h-6' />
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                }
                            </div>
                            {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
                            {errors && <div className='hidden'>
                                {errors.maxNumber && toast.error('Only 1 image allowed !', { id: 'maxnumberlogo' })}
                                {errors.acceptType && toast.error("Only images can be uploaded !", { id: 'typelogo' })}
                                {errors.maxFileSize && toast.error("File size too large !", { id: 'filesizelogo' })}
                            </div>
                            }
                        </div>
                    )}
                </ImageUploading>
            </Form.Group>

            <hr className="my-5" />
            <div className="text-center">
                <button className="bg-red-500 p-2 rounded-lg text-white" type="submit" onClick={handleSubmit} disabled={loading} >
                    Save
                </button>
            </div>

        </Form>
    )
}
