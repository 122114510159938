import axios from 'axios'
import React, { useEffect } from 'react'
import reducer from './Reducer'
import { account_api, product_api } from "../Utils/constants";
import { useReducer } from 'react';
import Cookies from 'js-cookie';

// Get Local Storage Cart 
const getCartLocalStorage = () => {
    const init = localStorage.getItem('cart')
    if (init) {
        return JSON.parse(localStorage.getItem('cart'))
    }
    else {
        return []
    }
}

// Get Local Storage Wishlist
const getWishlistLocalStorage = () => {
    const init = localStorage.getItem('wishlist')
    if (init) {
        return JSON.parse(localStorage.getItem('wishlist'))
    }
    else {
        return []
    }
}

//Get JWT from local storage
const getJWTLocalStorage = () => {
    const init = Cookies.get('token')
    if (init) {
        return Cookies.get('token')
    }
    else {
        return ''
    }
}

// Custome Product Details
const customProductDetails = (productData) => {
    const productDetails = productData.map((data) => {
        return {
            "id": data?.id,
            "category": data?.category?.name,
            "title": data?.name,
            "slug": data?.name.replace(/[^a-zA-Z0-9]/g, '-'),
            "actualPrice": data?.actual_price,
            "price": data?.discounted_price,
            "unit": data?.unit,
            "description": data?.description,
            "rating": data?.rating,
            "featured": data?.is_featured,
            "offer": data?.is_offer,
            "images": data?.images
        }
    })
    return productDetails
}

/* STATES */
const initialState = {
    isLoading: false,
    products: [],
    filter_products: [],
    selected_product: [],
    categories: [],
    sort: 'price',
    selectedCategory: '',
    searchText: '',
    cart: getCartLocalStorage(),   //Cart to save in localstorage
    cartItems: [],    //Acutal cart
    wishlist: getWishlistLocalStorage(),
    total_items: 0,
    total_amount: 0,
    jwt: getJWTLocalStorage(),
    isAuthorized: false,
    user: {}
}

const AppContext = React.createContext()

const AppProvider = ({ children }) => {

    const [state, dispatch] = useReducer(reducer, initialState)

    //store JWT token
    const storeJWT = (jwt) => {
        dispatch({ type: 'STORE_JWT', payload: jwt })
        Cookies.set('token', jwt, {expires: 7, secure: true})
    }

    //Logout -> Remove JWT token and Remove user details
    const logout = () => {
        dispatch({ type: 'LOGOUT' })
        Cookies.remove('token')
    }

    //Get user data from JWT
    const getUserData = async (account_api, new_token) => {
        try {
            const response = await axios.get(`${account_api}/user`, { headers: { Authorization: `Bearer ${new_token ? new_token : state.jwt}` } });
            const data = response.data;
            // console.log(data)
            if (data) {
                dispatch({ type: 'GET_USER', payload: data.data })
            }
            else {
                logout()
            }
        }
        catch (error) {
            logout()
        }
    }

    useEffect(() => {
        if (state.jwt) {
            getUserData(account_api)
        }
        // eslint-disable-next-line
    }, [state.jwt])

    /*Fetch Products*/
    const fetchProducts = async (product_api) => {
        try {
            const response = await axios.get(`${product_api}/get_products`);
            const data = response.data.data
            // console.log(data)
            if (data) {
                const new_data = customProductDetails(data)
                // console.log(new_data)
                dispatch({ type: 'GET_PRODUCTS', payload: new_data })
            }
        }
        catch (error) {
            // console.log(error)
        }
    }

    /*Fetch Categories*/
    const fetchCategories = async (product_api) => {
        try {
            const response = await axios(`${product_api}/get_categories`);
            const data = response.data.data;
            // console.log(data)
            if (data) {
                // console.log(new_data)
                dispatch({ type: 'GET_CATEGORIES', payload: data })
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    const fetchProductsBySearch = async (api) => {
        // try {
        //     const response = await axios.get(`${api}`);
        //     const data = response.data.data
        //     if (data) {
        //         const new_data = customProductDetails(data)
        //         dispatch({ type: 'FETCH_SEARCH_PRODUCTS', payload: new_data })
        //     }
        // }
        // catch (error) {
        //     // console.log(error)
        // }
        return;
    }


    /*SORTING*/
    const updateSort = (e) => {
        const value = e.target.value;
        dispatch({ type: 'UPDATE_SORT', payload: value })
    }

    const updateSelectedCategory = (category) => {
        // const value = e.target.value
        dispatch({ type: 'UPDATE_SELECT', payload: category })
    }

    const updateSelectedCategoryByText = (category) => {
        const value = category
        dispatch({ type: 'UPDATE_SELECT', payload: value })
    }

    /*SEARCHING*/
    const searchByName = (e) => {
        const value = e.target.value
        dispatch({ type: 'SEARCH_BY_NAME', payload: value })
    }

    /*Add to Cart*/
    const addToCart = (id, quantity) => {
        dispatch({ type: 'ADD_TO_CART', payload: { id, quantity } })
    }

    /*Local Storage Cart*/
    useEffect(() => {
        dispatch({ type: 'COUNT_CART_TOTAL' })
        localStorage.setItem('cart', JSON.stringify(state.cart))
    }, [state.cart, state.cartItems])

    //Actual Cart
    useEffect(()=>{
        //remove items from cart and wishlist if that product is removed by admin
        if(state.products.length){
        const newCart = state.cart.map((cartItem) => {
            const productItem = state.products.find((item) => item.id === cartItem.id);
            return productItem ? { ...cartItem } : null;
            }).filter((item) => item !== null);
        if(state.cart !== newCart)
            state.cart = newCart

        const newWishlist = state.wishlist.map((cartItem) => {
            const productItem = state.products.find((item) => item.id === cartItem.id);
            return productItem ? { ...cartItem } : null;
            }).filter((item) => item !== null);
        if(state.wishlist !== newWishlist)
            state.wishlist = newWishlist
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[state.products])
    useEffect(() => {
        dispatch({ type: 'DISPLAY_CART_ITEMS' })
    }, [state.cart, state.products])

    /*Remove Product*/
    const removeProduct = (_id) => {
        dispatch({ type: 'REMOVE_PRODUCT', payload: _id })
    }

    /*TOGGLE_AMOUNT QUANTITY*/
    const increaseQuantity = (_id) => {
        //console.log(_id)
        dispatch({ type: 'INCREASE_QUANTITY', payload: _id })
    }
    const decreaseQuantity = (_id) => {
        //console.log(_id)
        dispatch({ type: 'DECREASE_QUANTITY', payload: _id })
    }

    /*Clear Cart*/
    const clearCart = (_id) => {
        dispatch({ type: 'CLEAR_CART', payload: _id })
    }
    /*Clear Cart After Payment*/
    const emptyCart = (_id) => {
        dispatch({ type: 'CLEAR_CART_AFTER_PAYMENT', payload: _id })
    }


    /*Add to Wishlist*/
    const addToWishlist = (id, title, images, price, rating) => {
        dispatch({ type: 'ADD_TO_WISHLIST', payload: { id, title, images, price, rating } })
    }

    /*Local Storage Wishlist*/
    useEffect(() => {
        localStorage.setItem('wishlist', JSON.stringify(state.wishlist))
    }, [state.wishlist])

    /*Remove Product*/
    const removeWishlistProduct = (_id) => {
        dispatch({ type: 'REMOVE_WISHLIST_PRODUCT', payload: _id })
    }


    /*MOUNTING*/
    useEffect(() => {
        fetchProducts(product_api)
    }, [])
    useEffect(() => {
        fetchCategories(product_api)
    }, [])

    /*On search fix*/
    useEffect(() => {
        fetchProducts(product_api)
    }, [state.selected_product])

    useEffect(() => {
        dispatch({ type: 'SORT_PRODUCTS' });
    }, [state.sort])

    useEffect(() => {
        //send search text to api only 1second later
        const delayDebounceFn = setTimeout(() => {
            fetchProductsBySearch(`${product_api}/search_products/${state.searchText}`)
        }, 1000)
        return () => clearTimeout(delayDebounceFn)
    }, [state.searchText])

    useEffect(() => {
        dispatch({ type: 'LOAD_CATEGORIES' })
    }, [state.selectedCategory])


    return <AppContext.Provider
        value={{
            ...state,
            getUserData,
            storeJWT,
            logout,
            updateSort,
            updateSelectedCategory,
            updateSelectedCategoryByText,
            searchByName,
            addToCart,
            removeProduct,
            increaseQuantity,
            decreaseQuantity,
            clearCart,
            emptyCart,
            addToWishlist,
            removeWishlistProduct
        }}>
        {children}
    </AppContext.Provider>
}

export { AppContext, AppProvider }