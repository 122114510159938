import React from 'react'
import CartItem from '../../Components/CartItem/CartItem'
import { AppContext } from '../../Context/Context'
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { BsCart4 } from 'react-icons/bs'


export default function Cart() {
  const { cartItems, total_amount, isAuthorized } = useContext(AppContext);

  if (cartItems.length < 1) {
    return <section className='flex flex-col justify-center items-center h-screen gap-6 pb-8'>
      <BsCart4 className=' text-[15rem]' />
      <div className='fs-1'>
        Your Cart is empty!
      </div>
      <Link to='/products'>
        <button className='buy-btn fs-4 p-2'>
          Shop Now!
        </button>
      </Link>
    </section>
  }

  return (


    <div className="container py-5 h-100">
      <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="col-12">
          <div className="card card-registration card-registration-2" style={{ borderRadius: '15px' }}>
            <div className="card-body p-0">
              <div className="row g-0">
                <div className="col-lg-8">
                  <div className="p-5">
                    <div className="d-flex justify-content-between align-items-center mb-5">
                      <h1 className="fw-bold fs-2 mb-0 text-black">Shopping Cart</h1>
                      <h6 className="mb-0 text-muted">{cartItems.length} items</h6>
                    </div>
                    <hr className="my-4" />

                    <CartItem />

                    <div className="pt-5">
                      <h6 className="mb-0"><a href="#!" className="text-body"><i
                        className="fas fa-long-arrow-alt-left me-2"></i>Back to shop</a></h6>
                    </div>
                  </div>
                </div>


                {/* For Medium Screen device and above Summary View */}
                <div className="col-lg-4 bg-grey hidden md:block">
                  <div className="p-5">
                    <h3 className="fw-bold mb-5 mt-2 pt-1 fs-2">Summary</h3>
                    <hr className="my-4" />

                    <div className="d-flex justify-content-between mb-4">
                      <h5 className="text-uppercase">Sub Total</h5>
                      <h5>Rs. {total_amount}</h5>
                    </div>

                    <div className="d-flex justify-content-between mb-4">
                      <h5 className="text-uppercase">Tax</h5>
                      <h5>Inclusive</h5>
                    </div>
                    {/* <h5 className="text-uppercase mb-3">Shipping</h5>
                    <div className="mb-4 pb-2">
                      <select className="select">
                        <option value="1">Inside Kathmandu Valley Rs.100</option>
                        <option value="2">Outside Kathmandu Valley Rs. 200</option>
                      </select>
                    </div> */}
                    {/* <h5 className="text-uppercase mb-3">Promo code</h5>
                  <div className="mb-5">
                    <div className="form-outline">
                      <input type="text" id="form3Examplea2" className="form-control form-control-lg" />
                      <label className="form-label" for="form3Examplea2">Enter your code</label>
                    </div>
                  </div> */}
                    <hr className="my-4" />
                    <div className="d-flex justify-content-between mb-5">
                      <h5 className="text-uppercase fw-bold">Total price</h5>
                      <h5 className='fw-bold'>Rs.{total_amount}</h5>
                    </div>
                    <Link to={`${isAuthorized ? '/checkout' : '/login/redirectToCheckout'}`}>
                      <button className='btn btn-primary w-full text-2xl'>Checkout</button>
                    </Link>
                  </div>
                </div>

                {/* For Mobile device Floating card Summary View */}
                <div className="w-full rounded shadow-lg sticky bottom-14 md:hidden z-50 bg-white">
                  <div className="p-3">
                    <h3 className="fw-bold mt-1 fs-2">Summary</h3>
                    <hr className="my-2" />
                    <div className="d-flex justify-content-between mb-1">
                      <h5 className="text-uppercase">Sub Total</h5>
                      <h5>Rs. {total_amount}</h5>
                    </div>
                    <div className="d-flex justify-content-between mb-1">
                      <h5 className="text-uppercase">Tax</h5>
                      <h5>Inclusive</h5>
                    </div>
                    {/* <h5 className="text-uppercase mb-1">Shipping</h5>
                    <div className="">
                      <select className="select">
                        <option value="1">Inside Kathmandu Valley Rs.100</option>
                        <option value="2">Outside Kathmandu Valley Rs. 200</option>
                      </select>
                    </div> */}
                    <hr className="my-2" />
                    <div className="d-flex justify-content-between mb-2">
                      <h5 className="text-uppercase fw-bold">Total price</h5>
                      <h5 className='fw-bold'>Rs. {total_amount}</h5>
                    </div>
                    <Link to={`${isAuthorized ? '/checkout' : '/login/redirectToCheckout'}`}>
                      <button className='btn btn-primary w-full text-xl'>Checkout</button>
                    </Link>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>




  )
}
