import React from 'react'
import { AppContext } from '../Context/Context';
import { useContext } from 'react';

export default function AddToWishlist({children, item, rating}) {

  const { addToWishlist } = useContext(AppContext);
  const { id, title, images, price } = item;
  return (
    <div onClick={() => addToWishlist(id, title, images, price, rating)}>
        {children}
    </div>
  )
}
