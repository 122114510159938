import React from 'react'
import { useState, useContext } from 'react'
import { useEffect } from 'react'
import { AiFillShop } from 'react-icons/ai'
import { MdOutlineShoppingCart } from "react-icons/md";
import { IoIosSearch } from "react-icons/io";
import { IoHomeOutline } from "react-icons/io5";
import { Link } from 'react-router-dom'
import { AppContext } from '../../Context/Context'
import SearchModalBody from './SearchModalBody';
import Modal from 'react-bootstrap/Modal'

export default function MobileNavbar() {

    const { cart } = useContext(AppContext)

    //Get Current Screen size
    const [screenSize, setScreenSize] = useState(getCurrentDimension());
    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }
    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);
        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize])

    //Search Modal
    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);
    const SearchModal = () => {
        return (
            <Modal show={showModal} onHide={handleCloseModal} size='lg'>
                <Modal.Header>
                    <Modal.Title className='w-full flex flex-row justify-end items-center'>
                        <button onClick={handleCloseModal}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SearchModalBody closeModal={handleCloseModal} />
                </Modal.Body>
            </Modal>
        )
    }


    if (screenSize.width < 700) return (
        //Bottom Navbar
        <div className='grid grid-cols-4 pt-2 pb-2 items-end bg-white sticky bottom-0 z-[1050]'>
            <Link to="/" className='flex flex-col justify-center items-center cursor-pointer gap-1'>
                <IoHomeOutline className='w-6 h-6' />
                <p className='text-[11px]  fw-bold'>Home</p>
            </Link>
            <div onClick={handleShowModal} className='flex flex-col justify-center items-center cursor-pointer gap-1'>
                <IoIosSearch className='w-6 h-6 ' />
                <p className='text-[11px]  fw-bold'>Search</p>
            </div>
            <Link to="/products" className='flex flex-col justify-center items-center cursor-pointer gap-1'>
                <AiFillShop className='w-6 h-6 ' />
                <p className='text-[11px]  fw-bold'>Shop</p>
            </Link>
            <Link to="/cart" className='flex flex-col justify-center items-center cursor-pointer gap-1'>
                <div className="relative">
                    <div className="-top-2 absolute left-4">
                        <p className="flex h-[5px] w-[5px] items-center justify-center rounded-full bg-theme-orange-dark text-white p-[10px] text-xs ">{cart.length}</p>
                    </div>
                    <MdOutlineShoppingCart className='w-6 h-6 ' />
                </div>
                <p className='text-[11px]  fw-bold'>Cart</p>
            </Link>
            <SearchModal />
        </div>
    )
}
