import React from 'react'
import { AppContext } from '../../Context/Context'
import { useContext } from 'react'
// import QuantityToggle from '../../Pages/Cart/QuantityToggle'
import { AiFillDelete } from 'react-icons/ai'
import { FaMinus, FaPlus } from 'react-icons/fa'
import Table from 'react-bootstrap/Table';


export default function CartItem() {
    const { cartItems, removeProduct, increaseQuantity, decreaseQuantity } = useContext(AppContext);
    return (
        <>
            <Table className='hidden md:!table w-full'>
                <tbody className='!w-full align-middle'>
                    {cartItems.map((item, index) => (
                        <tr key={index}>
                            <td className=''>
                                <img
                                    src={item.images ? item.images[0] : ''}
                                    className="img-fluid rounded-3 min-w-12 max-w-40" alt="Cotton T-shirt" />
                            </td>
                            <td>
                                <h6 className="text-black mb-0 fs-4 text-center md:!text-left">{item.title}</h6>
                            </td>
                            <td className='!w-fit'>
                                <div className='flex items-center justify-center border-2 rounded-lg p-1 text-white bg-theme-red !border-theme-red text-md w-fit mt-2 mb-1'>
                                    <button className='px-1' onClick={() => decreaseQuantity(item.id)}>
                                        <FaMinus className='w-3 h-3' />
                                    </button>
                                    <span className='px-2'>{item.quantity}</span>
                                    <button className='px-1' onClick={() => increaseQuantity(item.id)}>
                                        <FaPlus className='w-3 h-3' />
                                    </button>
                                </div>
                                <h6 className="mb-0 fs-5 md:!hidden">@ Rs. {item.price}</h6>
                            </td>
                            <td>
                                <h6 className="mb-0 fs-5">@ Rs. {item.price}</h6>
                            </td><td>
                                <div className='fs-3' onClick={() => { removeProduct(item.id) }}><AiFillDelete className=' fill-red-500 cursor-pointer' /></div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <div className='md:!hidden'>
                {cartItems.map((item, index) => (
                    <div key={index}>
                        <div className='w-full flex flex-col justify-center items-center gap-2'>
                            <img
                                src={item.images ? item.images[0] : ''}
                                className="img-fluid rounded-3 max-w-48" alt="Cotton T-shirt" />
                            <h6 className="text-black mb-0 fs-4 text-center md:!text-left">{item.title}</h6>
                            <div className="flex justify-center gap-2 items-center">
                                <div className='border-2 rounded-lg p-1 text-white bg-theme-red !border-theme-red text-md w-fit mt-2 mb-1'>
                                    <button className='px-1' onClick={() => decreaseQuantity(item.id)}>
                                        <FaMinus className='w-3 h-3' />
                                    </button>
                                    <span className='px-2'>{item.quantity}</span>
                                    <button className='px-1' onClick={() => increaseQuantity(item.id)}>
                                        <FaPlus className='w-3 h-3' />
                                    </button>
                                </div>
                                <h6 className="mb-0 fs-5 md:!hidden">@ Rs. {item.price}</h6>
                            </div>
                            <div className='fs-3' onClick={() => { removeProduct(item.id) }}><AiFillDelete className=' fill-red-500 cursor-pointer' /></div>
                        </div>
                        <hr className="my-4" />
                    </div>
                ))}
            </div>
        </>

    )
}
