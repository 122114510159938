import axios from 'axios'
import React from 'react'
import { useState } from 'react'
import OTPInput from 'react-otp-input'
import { account_api } from '../../Utils/constants'

export default function ChangePassword({setStep}) {
    const [loading, setLoading] = useState(false)
    const [otp, setOtp] = useState(null)
    const [invalidOTP, setInvalidOTP] = useState(false)
    const [errMssg, setErrMssg] = useState('')

    //Credentials
    const [credentials, setCredentials] = useState({password:'', confirm_password:''})
    const onChange = (e) => {
        setCredentials({...credentials, [e.target.name]: e.target.value})
    }

    //On Form Submission
    const onSubmit = (e) => {
        e.preventDefault()
        setErrMssg('')
        if(credentials.password==='' || credentials.confirm_password==='' || otp===null){
            setErrMssg("All field are required!")
            return
        }
        if(credentials.password.length < 8){
            setErrMssg("Password must be at least 8 characters!")
            return
        }
        if(credentials.password !== credentials.confirm_password){
            setErrMssg("Password must be same!")
            return
        }
        setLoading(true)
        axios.post(`${account_api}/change_password`,{"otp":Number(otp),"password":credentials.password})
        .then(function(response){
            if(response.data.success){
                setStep(3)
                setLoading(false)
            }
            else{
                setErrMssg("Could not change password!")
            }
            setLoading(false)
        })
        .catch(function(error){
            console.log(error)
            if(error.response.status===400){
                setInvalidOTP(true)
                setErrMssg("Invalid OTP!")
            }
            else {
                setErrMssg("Internal Server Error! Try again later.")
            }
            setLoading(false)
        })
    }

    return (
        <div class="w-full h-100">
            <div class="flex flex-col items-center mt-5">
            <p className="text-2xl font-semibold mt-3 mb-3">Enter otp sent to your email</p>
                <OTPInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={5}
                    renderSeparator={<span>&emsp;&emsp;</span>}
                    renderInput={(props) => <input {...props} />}
                    inputStyle={`!w-[2em] bg-[#C9D7EE] fs-4 mt-8 mb-6 ${invalidOTP ? 'ring-[2px] ring-red-500 dark:bg-black dark:ring-[2px] dark:ring-red-500' : 'ring-[1px] ring-lightpurple focus:border-sky-200 focus:ring-sky-200 focus:ring-1 dark:bg-black dark:ring-1 dark:ring-[#ffffffCC]'} rounded-[10px] focus:outline-none h-12 text-center`}
                />
            </div>


            <div class="my-4 border-b text-center">
                <div
                    class="leading-none px-2 inline-block text-lg text-gray-600 tracking-wide font-medium bg-white transform translate-y-1/2"
                >
                    Set a New Password
                </div>
            </div>



            <form class="mt-6" onSubmit={onSubmit}>

                <div class="mt-4">
                    <label class="block text-gray-700">Password</label>
                    <input type="password" name="password" placeholder="Enter Password" minlength="6" class="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500
                focus:bg-white focus:outline-none" value={credentials.password} onChange={onChange} required />
                </div>

                <div class="mt-4">
                    <label class="block text-gray-700">Confirm Password</label>
                    <input type="password" name="confirm_password" placeholder="Enter Password" minlength="6" class="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500
                focus:bg-white focus:outline-none" value={credentials.confirm_password} onChange={onChange} required />
                </div>

                <div class="text-center mt-2">
                    <p className="text-lg font-semibold text-red-500">{errMssg}</p>
                </div>

                {loading ?
                    <button
                        type="submit"
                        class="opacity-60 w-full block bg-indigo-500 hover:bg-indigo-400 focus:bg-indigo-400 text-white font-semibold rounded-lg px-4 py-3 mt-6"
                        disabled
                    >Reseting...
                        <div class="spinner-border ml-2 w-5 h-5" role="status" />
                    </button>
                    :
                    <button
                        type="submit"
                        class="w-full block bg-indigo-500 hover:bg-indigo-400 focus:bg-indigo-400 text-white font-semibold rounded-lg px-4 py-3 mt-6"
                    >Reset</button>
                }
            </form>

        </div>
    )
}
