import React from 'react'
import './Testimonials.css'
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';
import { LeftArrow, RightArrow } from '../../Utils/Arrow';
import Rating from '../Rating/Rating';

let reviews = require('../../Utils/googleReviews.json');

export default function Testimonials() {

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
        const { carouselState: { currentSlide } } = rest;
        return (
            <>
                <div className="absolute top-[45%] left-6 md:left-10">
                    <div className={currentSlide === 0 ? 'disable' : ''} onClick={() => previous()} ><LeftArrow /></div>
                </div>
                <div className="absolute top-[45%] right-6 md:right-10">
                    <div onClick={() => next()} > <RightArrow /> </div>
                </div>
            </>
        );
    };

    return (
        <section className='pt-20 md:pt-24 pb-4 md:!pb-16'>
            <div className='mb-3 px-[18px] flex flex-col gap-3 md:flex-row md:justify-between md:items-center md:px-24 md:mb-5'>
                <p className="text-2xl md:text-4xl fw-bold text-[#922636] mb-1 md:mb-5">Customer's Review</p>
            </div>
            <div className='relative px-3 md:!px-20 h-fit pt-3'>
                <Carousel arrows={false} responsive={responsive} renderButtonGroupOutside={true} customButtonGroup={<ButtonGroup />}>
                    {reviews.map((review, index) => (
                        <a href={review.review_link} rel='noreferrer' target='_blank' className='!h-[95%] mt-1 mb-1 py-3 px-3 mx-1 sm:!mx-2 md:!mx-4 ring-2 border rounded-lg ring-theme-orange-dark flex flex-col items-center justify-center gap-2' key={index}>
                            <img src={review.author_image} className='w-20 h-20' alt="User" />
                            <p className='text-2xl -mb-3 cursive'>{review.author_title}</p>
                            <Rating rating={review.review_rating} showRate={false}/>
                            <p className='px-4 text-sm text-center'>{review.review_text}</p>
                        </a>
                    ))}
                </Carousel>
            </div>
        </section>
    )
}

