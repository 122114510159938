import React, { useEffect, useState } from 'react'
import { AiOutlineInstagram } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import { FaCircleArrowRight } from 'react-icons/fa6'
import { FiFacebook } from "react-icons/fi";
import { AiOutlineYoutube } from "react-icons/ai";
import axios from 'axios'
import {home_api} from '../../Utils/constants'

export default function Footer() {

        //fetch logo
        const [logo, setLogo] = useState('')
        useEffect(() => {
            axios.get(`${home_api}`)
                .then(function (response) {
                    if (response.data) {
                        setLogo(response.data.data.logo)
                    }
                })
        }, [])

    return (
        <div className="footer">
            <div className='w-full h-fit py-14 px-[18px] md:px-24 flex flex-col'>
                <div>
                    <div className='mb-3 px-[18px] flex flex-col gap-3 md:flex-row md:justify-between md:items-center md:mb-5'>
                        <p className="text-2xl md:text-4xl fw-bold">Locate Us</p>
                        <a href="https://maps.google.com/maps?ll=27.678061,85.321182&z=16&t=m&hl=en&gl=NP&mapclient=embed&cid=4234288973318857104" target='_blank' rel='noreferrer'>
                            <div className='flex flex-row justify-end md:justify-normal gap-2 cursor-pointer'>
                                <p className="text-xl md:text-2xl fw-bold">View in Map</p>
                                <FaCircleArrowRight className={`text-3xl hover:text-4xl text-theme-orange-dark`} />
                            </div>
                        </a>
                    </div>
                </div>
                <iframe title="Google Map" className='w-full md:h-72' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2949.7168557673285!2d85.31989394603049!3d27.678871465794852!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19c91ebf147b%3A0x3ac3376313062190!2sAnnapurna%20Sweets%20%26%20Fast%20Food%20Restaurant!5e0!3m2!1sen!2snp!4v1687695970741!5m2!1sen!2snp" style={{ border: '0' }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div className="container">
                <div className='w-full h-1 bg-theme-orange-dark mb-3' />
                <div className='grid grid-cols-2 lg:grid-cols-3'>
                    <div className='col-span-2 lg:col-span-1 flex flex-col justify-center items-center pb-2 md:!pb-5 text-md md:text-xl'>
                            <img src={logo} alt="Logo" className=' w-40 md:!w-56' />
                            <p className='mt-3'>&nbsp;Patandhoka Lalitpur</p>
                            <p>&nbsp;01-5005500</p>
                            <p>&nbsp;annapurnasweets@gmail.com</p>
                    </div>
                    <div className='flex flex-col items-center mt-3 text-sm md:text-xl px-2 justify-end pb-2 md:!pb-5'>
                        <div className='w-fit'>
                            <p><Link to="/terms">Privacy Policy</Link></p>
                            <p>About Us</p>
                            <p>Contact</p>
                            <p>Services Preparation</p>
                            <p>My Account</p>
                        </div>
                    </div>
                    <div className='flex flex-col items-center justify-end pb-2 md:!pb-5'>
                        <div className='w-fit flex flex-col gap-3'>
                            <div className='md:mb-2 text-md md:text-2xl underline-offset-4 underline'>Follow Us</div>
                            <Link to="https://www.facebook.com/AnnapurnaPatan/" target='_blank' className='flex flex-row items-center gap-2 cursor-pointer text-xs md:text-xl'><i className='text-xl md:text-3xl'><FiFacebook /></i>Facebook</Link>
                            <Link to="https://www.instagram.com/annapurnasweetsnepal/" target='_blank' className='flex flex-row items-center gap-2 cursor-pointer text-xs md:text-xl'><i className='text-xl md:text-3xl'><AiOutlineInstagram /></i>Instagram</Link>
                            <div className='flex flex-row items-center gap-2 cursor-pointer text-xs md:text-xl'><i className='text-xl md:text-3xl'><AiOutlineYoutube /></i>Youtube</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
