import { ref, getDownloadURL, uploadBytes, deleteObject } from "firebase/storage";
import storage from "../firebaseConfig";

async function upload(image, folder) {
    const metadata = {
        cacheControl: 'public,max-age=2628000'
      };
    const storageRef = ref(storage, `/${folder}/${Date.now()}_${image.name.replace(/[^a-zA-Z0-9_. ]/g, '').replace(/ /g,"_")}`);
    const response = await uploadBytes(storageRef, image, metadata);
    const url = await getDownloadURL(response.ref);
    return url;
}

export async function deleteFirebaseImage(image, folder) {
    let filename = image.substr(image.indexOf('%2F') + 3, (image.indexOf('?')) - (image.indexOf('%2F') + 3));
    filename = filename.replace('%20', ' ');
    const desertRef = ref(storage, `${folder}/${filename}`);
    // Delete the file
    deleteObject(desertRef).then(() => {
        console.log("File Deleted")
        return true
    }).catch((error) => {
        console.log("Error")
        return false
    });
}

export async function uploadFirebaseImage(images, folder) {
    if(images){
        let resolvedValueArray = []
        const imagePromises = Array.from(images, (image) => upload(image.file, folder));
        const imageRes = await Promise.all(imagePromises)
        imageRes.forEach((resolvedValue, index) => {
            resolvedValueArray.push(resolvedValue);
        });
        return resolvedValueArray
    }
    return null
}