import React, { useEffect } from 'react'
import ProductCard from '../../Components/ProductCard/ProductCard'
import './Products.css'
import { AppContext } from '../../Context/Context'
import { useContext } from 'react'
import Loader from '../../Components/Loader/Loader'
import { useLocation } from "react-router-dom";
import Carousel from "react-multi-carousel";
import { LeftArrow, RightArrow } from '../../Utils/Arrow'

export default function Products() {

  const { products, categories, sort, updateSort, selectedCategory, updateSelectedCategory, updateSelectedCategoryByText } = useContext(AppContext);

  //Get Category hash
  const { hash } = useLocation();
  useEffect(() => {
    if (hash) {
      updateSelectedCategory(hash.slice(1).replace('_', " "))
      //delete hash from url
      var uri = window.location.toString();
      if (uri.indexOf("#") > 0) {
        var clean_uri = uri.substring(0,
          uri.indexOf("#"));
        window.history.replaceState({},
          document.title, clean_uri);
      }
    }
    else {
      updateSelectedCategoryByText("all")
    }
    // eslint-disable-next-line
  }, [hash])

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const { carouselState: { currentSlide } } = rest;
    return (
      <>
        <div className="absolute top-1 -left-6">
          <div className={currentSlide === 0 ? 'disable' : ''} onClick={() => previous()} ><LeftArrow size="lg" /></div>
        </div>
        <div className="absolute top-1 -right-6">
          <div onClick={() => next()} > <RightArrow size="lg" /> </div>
        </div>
      </>
    );
  };

  return (
    <div className='products p-[12px] lg:p-8'>
      <div className='lg:grid lg:grid-cols-12'>
        <div className='hidden lg:block lg:col-span-3 lg:h-[60vh] lg:sticky lg:top-4'>
          <div className='mt-28'>
            <div className='ring-1 ring-theme-orange-dark w-full h-fit p-3 fs-3 text-theme-red flex flex-col gap-2 rounded-3xl'>
              <div className='flex flex-row gap-3 cursor-pointer'>
                <div className={`w-2 ${selectedCategory === "all" ? 'bg-theme-orange-dark' : ''}`} />
                <p onClick={() => updateSelectedCategory("all")}>All</p>
              </div>
              {categories && categories.map((category) => (
                <div key={category.id} className='flex flex-row gap-3 cursor-pointer'>
                  <div className={`w-2 ${selectedCategory === category.name ? 'bg-theme-orange-dark' : ''}`} />
                  <p onClick={() => updateSelectedCategory(category.name)}>{category.name}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className='mt-4 lg:mt-0 lg:col-span-9'>
          <div className='flex flex-col w-full'>
            <div className='fs-6 ml-auto'>
              <div className='w-fit h-fit p-2 ring-1 ring-theme-orange-dark rounded-2xl'>
                SORT BY - &ensp;
                <select className='border-2 w-[70%] md:w-auto fs-6 bg-transparent outline-none border-none text-theme-orange-dark' name="sort" id="sort" value={sort} onChange={updateSort}>
                  {/* <option>Sort By</option> */}
                  <option value="title">A to Z</option>
                  <option value="-title">Z to A</option>
                  <option value="-price">Price: Highest to Lowest</option>
                  <option value="price">Price: Lowest to Highest</option>
                </select>
              </div>
            </div>
            <div className='px-4 mt-4 w-full lg:hidden'>
              <div className='relative'>
                <Carousel responsive={{
                  tablet: { breakpoint: { max: 1024, min: 464 }, items: 4 },
                  mobile: { breakpoint: { max: 464, min: 0 }, items: 3 }
                }} arrows={false} renderButtonGroupOutside={true} customButtonGroup={<ButtonGroup />}>
                  {categories && categories.map((category, index) => (
                    <div key={index} onClick={() => updateSelectedCategory(category.name)} className='fs-6 text-theme-red'>{category.name}</div>
                  ))}
                </Carousel>
              </div>
            </div>
            <p className='mt-3 ml-5 lg:ml-16 mb-3 uppercase fs-2 text-theme-red-dark'>
              {selectedCategory}
            </p>
          </div>
          {!products ? <Loader /> :
            <div className='flex flex-wrap gap-3 md:!gap-8 justify-center'>
              {products.map(product => (
                <div key={product.id}>
                  <ProductCard item={product} />
                </div>
              ))}
            </div>
          }
        </div>
      </div>
      {/* <Filter />
      {!products ? <Loader /> :
        <div className='mt-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-14 md'>
          {products.map(product => (
            <div key={product.id}>
              <ProductCard item={product} />
            </div>
          ))}
        </div>
      } */}
    </div>

  )
}
