import React, { useState } from 'react'
import RegisterForm from './RegisterForm'
import OTP from './OTP'
import AccountRegistered from './AccountRegistered'
import ScrollToTop from '../../Components/ScrollToTop'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import { AppContext } from '../../Context/Context'

export default function Register() {

  //To navigate to homepage if logged in
  const navigate = useNavigate()
  const { jwt } = useContext(AppContext)
  useEffect(()=>{
    if(jwt) {
      navigate("/")
    }
  },[jwt,navigate])

  //Switch between 3 registration steps
  const [step, setStep] = useState(1)
  //Pass jwt token between steps
  const [token, setToken] = useState('')
  //Pass login details to last step to auto login
  const [loginDetails, setLoginDetails] = useState({email:'', password:''})

  return (
    <>
    <ScrollToTop />
    <div class="min-h-screen bg-gray-100 text-gray-900 flex justify-center">
      <div
        class="max-w-screen-xl m-0 sm:m-20 bg-white shadow sm:rounded-lg flex justify-center flex-1"
      >
        <div class="lg:w-1/2 xl:w-5/12 p-6 sm:p-12">
          <div class="mt-12 flex flex-col items-center">

            {step===1?<RegisterForm setStep={setStep} setToken={setToken} loginDetails={loginDetails} setLoginDetails={setLoginDetails} />:(step===2?<OTP setStep={setStep} token={token} />:<AccountRegistered loginDetails={loginDetails} />)}
          
          </div>
        </div>
        <div class="flex-1 bg-indigo-100 text-center hidden lg:flex">
          <div
            class="m-12 xl:m-16 w-full bg-contain bg-center bg-no-repeat"
            style={{ backgroundImage: "url('/assets/pexels-igor-ovsyannykov-205961.webp')" }}
          ></div>
        </div>
      </div>

    </div>
    </>
  )
}
