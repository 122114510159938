import React, { useState } from 'react'
import ImageUploading from 'react-images-uploading';
import { FaDownload } from "react-icons/fa";
import { CiCircleRemove } from "react-icons/ci";
import toast from 'react-hot-toast';
import Form from 'react-bootstrap/Form';
import axios from 'axios'
import { product_api } from '../../../Utils/constants';
import { deleteFirebaseImage, uploadFirebaseImage } from '../../../Utils/FirebaseStorage';
import { useContext } from 'react';
import { AppContext } from '../../../Context/Context';
import BootstrapToast from '../../BootstrapToast/BootstrapToast';

export default function AddCategoryForm({ type, data, closeModal }) {
    const {jwt} = useContext(AppContext)

    const [credentials, setCredentials] = useState(
        {
            id: type==="edit"?data.id:'',
            category: type==="edit"?data.name:'', 
            image: type==="edit"?data.image_url:''
        })
    const [loading, setLoading] = useState(false)

    //Images
    const [images, setImages] = useState([]);
    const maxNumber = 1;
    const onImageUpload = async (imageList, addUpdateIndex) => {
        // console.log("imagelist " + imageList, addUpdateIndex)
        setImages(imageList);
    };

    //Deleting image
    const [deletedImageUrl, setDeletedImageUrl] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log(images)
        // console.log(credentials.image)
        if(!images.length && !credentials.image){
            toast.error("One image must be selected !")
            return;
        }
        setLoading(true)
        let image_url = await uploadFirebaseImage(images,"categoryImages")
        if(type==="edit"){
            if(deletedImageUrl){
                await deleteFirebaseImage(deletedImageUrl,"categoryImages")
                setDeletedImageUrl('')
            }
            axios.post(`${product_api}/update_category`,{
                id: credentials.id,
                name: credentials.category,
                image_url: images?image_url[0]:credentials.image
            }, {headers: {Authorization: `Bearer ${jwt}`}})
            .then(()=>{
                toast.success("Updated Category !")
                closeModal()
                setImages([])
            })
            .catch((e)=> toast.error("Server Error !"))
            .finally(()=> setLoading(false))
            return;
        }
        axios.post(`${product_api}/create_category`,{
            name: credentials.category,
            image_url: image_url[0]
        }, {headers: {Authorization: `Bearer ${jwt}`}})
        .then(()=>{
            // console.log(res)
            toast.success("Category Added !")
            setCredentials({category:'', image:''})
            setImages([])
        })
        .catch((e)=> toast.error("Server Error !"))
        .finally(()=> setLoading(false))
    }

    return (
        <Form onSubmit={handleSubmit} className={`font-sans ${loading?'opacity-50':''}`}>

            <Form.Group className="mb-4">
                <Form.Label className='font-medium'>Category</Form.Label>
                <Form.Control placeholder="Enter Categroy" name="category" onChange={(e)=>setCredentials({...credentials, category:e.target.value})} value={credentials.category} required/>
            </Form.Group>

            <Form.Group className="mb-4 w-full">
                <Form.Label className='font-medium'>Upload Image</Form.Label>
                <ImageUploading
                    value={images}
                    onChange={onImageUpload}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                >
                    {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                        errors
                    }) => (
                        // write your building UI
                        <div className="upload__image-wrapper">
                            <div
                                style={isDragging ? { opacity: '0.5' } : undefined}
                                className={`w-full h-80 bg-[#F2F2F2] ring-1 ring-black rounded-xl flex ${imageList.length || (credentials.image && credentials.image.length) ? 'flex-wrap' : 'justify-center items-center flex-col'} gap-3`}
                                onClick={credentials.image?void(0):onImageUpload}
                                {...dragProps}
                            >
                                {!imageList.length && !credentials.image.length ?
                                    <>
                                        <FaDownload className='w-10 h-10' />
                                        <p>Click or Drop here</p>
                                    </>
                                    :
                                    <>
                                        {
                                            credentials.image &&
                                                <div className="flex flex-col p-2 gap-2">
                                                    <img src={credentials.image} alt="" width="100" className='rounded-xl' />
                                                    <div className="flex justify-center w-full">
                                                        <button onClick={(e) => {e.stopPropagation();setDeletedImageUrl(credentials.image);setCredentials({...credentials,image:''})}} className='w-full flex justify-center'>
                                                            <CiCircleRemove className='w-6 h-6' />
                                                        </button>
                                                    </div>
                                                </div>
                                            }
                                        {imageList.map((image, index) => (
                                            <div key={index} className="flex flex-col p-2 gap-2">
                                                <img src={image['data_url']} alt="" width="100" className='rounded-xl' />
                                                <div className="flex justify-center w-full">
                                                    <button onClick={(e) => {e.stopPropagation();onImageRemove(index)}} className='w-full flex justify-center'>
                                                        <CiCircleRemove className='w-6 h-6' />
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                }
                            </div>
                            {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
                            {errors && <div className='hidden'>
                                {errors.maxNumber && toast.error('Only 4 images allowed !', { id: 'maxnumber' })}
                                {errors.acceptType && toast.error("Only images can be uploaded !", { id: 'type' })}
                                {errors.maxFileSize && toast.error("File size too large !", { id: 'filesize' })}
                            </div>
                            }
                        </div>
                    )}
                </ImageUploading>
            </Form.Group>
            <hr className="!my-5" />
            <div className={`${type === "add" ? '' : 'text-center'}`}>
                <button className="bg-red-500 p-2 rounded-lg text-white" disabled={loading}>
                    {type === "add" ? 'Add Category' : 'Update Category'}
                </button>
            </div>
            {loading && <BootstrapToast />}
        </Form>
    )
}
