import React from 'react'

export default function SearchBox({setSearchItem}) {
    return (
        <div className="relative left-[25%] mb-8">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                </svg>
            </div>
            <input type="search" onChange={(e)=>setSearchItem(e.target.value)} className="block w-[50%] py-2 pl-10 pr-5 ps-10 text-sm rounded-3xl text-gray-900 border border-gray-300 bg-gray-50 focus:outline-none" placeholder="Search..." required />
        </div>
    )
}
