import React from 'react'
import WishlistItem from '../../Components/WishlistItem/WishlistItem'
import { BsCart4 } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { AppContext } from '../../Context/Context';

export default function Wishlist() {

    const { wishlist } = useContext(AppContext);

    if (wishlist.length < 1) {
        return <section className='flex flex-col justify-center items-center h-screen gap-6 pb-8'>
            <BsCart4 className=' text-[15rem]' />
            <div className='fs-1'>
                Your Wishlist is empty!
            </div>
            <Link to='/products'>
                <button className='buy-btn fs-4 p-2'>
                    Shop Now!
                </button>
            </Link>
        </section>
    }

    return (
        <div className='p-3'>
            <div class="rounded shadow-lg bg-white p-5">
                <div className="p-3">
                    <h1 className="fw-bold fs-2 mb-0 text-black">Wishlist</h1>
                    <hr className='my-4' />

                    {
                      wishlist.map(item => {
                        return (
                          <WishlistItem key={item.id} item={item} />
                        )
                      })
                    }

                </div>
            </div>
        </div>
    )
}
