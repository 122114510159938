import React, { useState } from 'react'
import { useContext } from 'react'
import { AppContext } from '../../Context/Context'
// import { AiFillStar } from 'react-icons/ai'
import { Link } from 'react-router-dom'

export default function SearchModalBody({closeModal}) {

  const [searchItem, setSearchItem] = useState('')
  const { products } = useContext(AppContext)

  //Filter data
  const [filteredData, setFilteredData] = useState([])
  const filterData = (searchTerm) => {
    if (searchTerm === '') {
      setFilteredData([])
      return;
    }
    const filteredData = products.filter((item) =>
      item.title.toLowerCase().includes(searchTerm.toLowerCase()) |
      item.category.toLowerCase().includes(searchTerm.toLowerCase()) |
      item.description.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filteredData)
  };
  return (
    <div className="flex flex-col gap-3 items-center">
      <div className="relative mb-1 w-[90%] lg:w-[50%]">
        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
          <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
          </svg>
        </div>
        <input autoFocus type="search" onChange={(e) => { filterData(e.target.value); setSearchItem(e.target.value) }} className="block w-[100%] py-2 pl-10 pr-5 ps-10 text-sm rounded-3xl text-gray-900 border border-gray-300 bg-gray-50 focus:outline-none" placeholder="Search..." required />
      </div>
      {
        searchItem && !filteredData.length &&
        <p className='py-3'>Not item found !</p>
      }
      {filteredData.slice(0, 5).map(item => (
        <Link key={item.id} className='w-[90%] lg:w-[50%]' to={`/products/${item.slug}`} onClick={closeModal}>
          <div className='ring-2 ring-theme-orange-dark rounded-lg p-2 m-1 flex flex-row gap-3 relative'>
            <img src={item.images[0]} alt="Product" className="w-32 md:w-48 rounded-lg" loading='lazy' />
            <div className='flex flex-col justify-between'>
              <div className='flex flex-col'>
                <p className='text-xl md:text-2xl'>{item.title}</p>
                <p className='text-xs md:text-md text-theme-orange-dark'>{item.category}</p>
              </div>
              <p className='text-xs md:text-md'>Rs.&nbsp;<span className='text-lg md:text-xl text-theme-red satoshi-regular'>{item.price}</span> per {item.unit}</p>
            </div>
            {/* <div className='absolute top-2 right-2'>
              <div className='flex flex-col items-center justify-center'>
                <AiFillStar style={{ color: '#FFD300' }} className="text-xl md:text-2xl" />
                <span className="-mt-1 text-xs md:text-md">{item.rating}</span>
              </div>
            </div> */}
          </div>
        </Link>
      ))}
    </div>
  )
}
