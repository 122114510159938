import React, { useEffect } from 'react'
import { useState } from 'react'
import { order_api } from '../../../Utils/constants'
import toast from 'react-hot-toast'
import axios from 'axios'
import Modal from 'react-bootstrap/Modal'
import { useContext } from 'react'
import { AppContext } from '../../../Context/Context'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { FaEdit, FaRegTrashAlt } from 'react-icons/fa'
import BootstrapToast from '../../BootstrapToast/BootstrapToast'

export default function ShippingCharges() {
    const { jwt } = useContext(AppContext)

    const [data, setData] = useState([])
    const [selectedData, setSelectedData] = useState()

    const fetchData = async () => {
        await axios.get(`${order_api}/shipping_charge`, { headers: { Authorization: `Bearer ${jwt}` } })
            .then(function (res) {
                setData(res.data.data)
            })
            .catch(() => toast.error("Some Error while fetching data !"))
    }

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line
    }, [])

    //Add/Update Modal
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const handleCloseUpdateModal = () => setShowUpdateModal(false);
    const handleCloseUpdateModalFetchData = () => { setShowUpdateModal(false); fetchData() }
    const handleShowUpdateModal = () => setShowUpdateModal(true);

    //Delete Modal
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const handleCloseDeleteModal = () => setShowDeleteModal(false);
    const handleCloseDeleteModalFetchData = () => { setShowDeleteModal(false); fetchData() }
    const handleShowDeleteModal = () => setShowDeleteModal(true);

    //Delete Shipping Charge
    const [loading, setLoading] = useState(false)
    const handleDeleteShippingCharge = async (data) => {
        // console.log(data)
        setLoading(true)
        await axios.delete(`${order_api}/shipping_charge`,
            { 
                headers: { Authorization: `Bearer ${jwt}` }, 
                data: {id:data.id}
            })
            .then(() => {
                toast.success("Deleted Shipping Charge !")
                handleCloseDeleteModalFetchData()
            })
            .catch(()=> toast.error("Server Error !"))
            .finally(() => setLoading(false))
    }

    //Form
    const [credentials, setCredentials] = useState({ id: '', charge: 0, location: '' })
    const handleSubmit = (e) => {
        e.preventDefault()
        //if id is present then it is update otherwise add
        setLoading(true)
        if(credentials.id){
            //update
            axios.patch(`${order_api}/shipping_charge`,credentials,{headers:{Authorization:`Bearer ${jwt}`}})
            .then(()=>{
                toast.success("Shipping Charge Updated !")
                handleCloseUpdateModalFetchData()
            })
            .catch(()=> toast.error("Server Error !"))
            .finally(()=>{setLoading(false);setCredentials({ id: '', charge: 0, location: '' })})
        }
        else {
            //add
            axios.post(`${order_api}/shipping_charge`,{
                location: credentials.location,
                charge: credentials.charge
            },{headers:{Authorization:`Bearer ${jwt}`}})
            .then(()=>{
                toast.success("Shipping Charge Added !")
                handleCloseUpdateModalFetchData()
            })
            .catch(()=> toast.error("Some Error Occurred !"))
            .finally(()=>{setLoading(false);setCredentials({ id: '', charge: 0, location: '' })})
        }
    }

    return (
        <div>
            <p className='text-2xl font-medium'>Shipping Charges</p>
            <div className='w-full flex justify-end mb-10'>
                <button className='btn btn-primary' onClick={()=>{handleShowUpdateModal();setCredentials({ id: '', charge: 0, location: '' })}}>Add</button>
            </div>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Location</th>
                        <th scope="col">Charge</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((data, index) => (
                            <tr key={data.id}>
                                <th scope="row">{index + 1}</th>
                                <td>{data.location}</td>
                                <td>{data.charge}</td>
                                <td>
                                    <button className='btn btn-outline-primary' onClick={() => { handleShowUpdateModal(); setCredentials(data) }}><FaEdit /></button>&ensp;
                                    <button className='btn btn-outline-danger' onClick={() => { handleShowDeleteModal(); setSelectedData(data) }}><FaRegTrashAlt /></button>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>



            <Modal show={showUpdateModal} onHide={handleCloseUpdateModal} size="lg">
                <Modal.Header>
                    <Modal.Title className='w-full flex flex-row justify-between items-center'>
                        <p>Add / Update Shpping Charges</p>
                        <button onClick={handleCloseUpdateModal}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit} className={`font-sans ${loading ? 'opacity-50' : ''}`}>

                        <Form.Group className="mb-4">
                            <Form.Label className='font-medium'>Location</Form.Label>
                            <Form.Control placeholder="Enter Location" name="location" onChange={(e) => setCredentials({ ...credentials, location: e.target.value })} value={credentials.location} required />
                        </Form.Group>

                        <Form.Group className="mb-4">
                            <Form.Label className='font-medium'>Charge</Form.Label>
                            <Form.Control placeholder="Enter Charge" type="number" name="charge" onChange={(e) => setCredentials({ ...credentials, charge: e.target.value })} value={credentials.charge} required />
                        </Form.Group>

                        <hr className="!my-5" />
                        <div className='text-center'>
                            <button className="bg-red-500 p-2 rounded-lg text-white" disabled={loading}>
                                {credentials.id ? 'Update Shipping Charge' : 'Add Shipping Charge'}
                            </button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
                <Modal.Header>
                    <Modal.Title className='w-full flex flex-row justify-between items-center'>
                        <p>Delete Shipping Charge</p>
                        <button onClick={handleCloseDeleteModal}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='font-sans'>Are you sure?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={handleCloseDeleteModal}>
                        No
                    </Button>
                    <Button variant="outline-danger" onClick={() => handleDeleteShippingCharge(selectedData)} disabled={loading}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            {loading && <BootstrapToast />}
        </div>
    )
}
