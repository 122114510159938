import React from 'react'
import { Link } from 'react-router-dom'
import ScrollToTop from '../../Components/ScrollToTop'

export default function CheckoutComplete() {
  return (
    <>
    <ScrollToTop />
    <div className='flex flex-col justify-center items-center gap-3 p-5'>
        <img src='/assets/order-placed.svg' className='w-1/2 md:w-1/3 lg:w-1/5 xl:w-1/6' alt="Order Placed"/>
        <p className='fs-1 fw-bold text-center'>Your order has been placed !</p>
        <p className='fs-3 fw-bold text-center'>We will contact you soon</p>
        <Link to="/products"><button className='btn btn-success btn-lg fs-4 mt-5'>Go Back to shopping</button></Link>
    </div>
    </>
  )
}
