import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Spinner from 'react-bootstrap/Spinner';

function BootstrapToast() {
  return (
    <ToastContainer className='p-3' position='top-center'>
      <Toast className="!shadow-md w-fit">
        <Toast.Body>
            <div className='px-2 flex flex-row gap-3 items-center'>
                <Spinner animation="border" role="status" className='w-5 h-5'>
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
                <p className="text-[17px]">Saving...</p>
            </div>
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
}

export default BootstrapToast;