import React from 'react'
import { AppContext } from '../Context/Context';
import { useContext } from 'react';

export default function AddToCart({children, item, quantity}) {
  const { addToCart } = useContext(AppContext);
  const { id } = item;
  return (
    <div onClick={() => addToCart(id, quantity)}>
        {children}
    </div>
  )
}
