import axios from 'axios'
import React from 'react'
import { useState } from 'react'
import { account_api } from '../../Utils/constants'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useContext } from 'react'
import { AppContext } from '../../Context/Context'
import { useEffect } from 'react'
import GoogleLoginButton from '../../Components/GoogleLoginButton/GoogleLoginButton'
import toast from 'react-hot-toast'

export default function Login() {

    //detect url parameter for redirectToCheckout
    const { param } = useParams()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    //Store jwt key function
    const { storeJWT, jwt, getUserData } = useContext(AppContext)

    //Check if user is already logged in and redirect to homepage
    useEffect(() => {
        if (jwt) {
            navigate("/")
        }
    }, [jwt, navigate])

    //Login form credentials
    const [credentials, setCredentials] = useState({ email: '', password: '' })
    const onChange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value })
    }
    const [errMssg, setErrMssg] = useState('')

    const onSubmit = (e) => {
        setErrMssg('')
        e.preventDefault()
        setLoading(true)
        if (credentials.email !== '' && credentials.password !== '') {
            axios.post(`${account_api}/login`, {
                email: credentials.email,
                password: credentials.password
            })
                .then(function (response) {
                    // console.log(response)
                    if (response.data.success) {
                        storeJWT(response.data.token)
                        getUserData(account_api, response.data.token)
                        setLoading(false)
                        toast.success("Logged In !")
                        if (param === 'redirectToCheckout') {
                            return navigate("/checkout")
                        }
                        return navigate("/");
                    }

                })
                .catch(function (error) {
                    // console.log(error)
                    toast.error("Some Error Occurred")
                    if (error.response.status === 401) {
                        setErrMssg("Email/Password Incorrect")
                    }
                    else {
                        setErrMssg("Some Error Occurred! Try again later.")
                    }
                    setLoading(false)
                })
        }
    }

    return (
        <section className="flex flex-col md:flex-row items-center">

            <div className="bg-white hidden lg:block w-full md:w-1/2 xl:w-2/3 ">
                <img src="/assets/valentines-day.webp" alt="" className="w-full h-full object-cover ml-8 xl:ml-0" />
            </div>

            <div className="bg-white w-full md:max-w-md lg:max-w-full md:mx-auto md:mx-0 md:w-1/2 xl:w-1/3 px-6 lg:px-16 xl:px-12
        flex ">

                <div className="w-full h-100">
                    {param === 'redirectToCheckout' ? <div className='text-center fs-4 text-danger mt-2'>You need to login before checkout</div> : <></>}
                    <h1 className="text-xl md:text-2xl font-bold leading-tight mt-4 mb-2">Log in to your account</h1>

                    <div className="flex flex-col items-center">
                        <GoogleLoginButton setErrMssg={setErrMssg} />
                    </div>
                    <div className="my-4 border-b text-center">
                        <div
                            className="leading-none px-2 inline-block text-lg text-gray-600 tracking-wide font-medium bg-white transform translate-y-1/2"
                        >
                            Or Log In with e-mail
                        </div>
                    </div>



                    <form className="mt-6" onSubmit={onSubmit}>
                        <div>
                            <label className="block text-gray-700">Email Address</label>
                            <input type="email" name="email" placeholder="Enter Email Address" className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none" autoFocus autoComplete value={credentials.email} onChange={onChange} required />
                        </div>

                        <div className="mt-4">
                            <label className="block text-gray-700">Password</label>
                            <input type="password" name="password" placeholder="Enter Password" minlength="6" className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500
                focus:bg-white focus:outline-none" value={credentials.password} onChange={onChange} required />
                        </div>

                        <div className="text-right mt-2">
                            <Link to="/reset-password" className="text-sm font-semibold text-gray-700 hover:text-blue-700 focus:text-blue-700">Forgot Password?</Link>
                        </div>

                        <div className="text-center mt-2">
                            <p className="text-lg font-semibold text-red-500">{errMssg}</p>
                        </div>

                        {loading ?
                            <button
                                type="submit"
                                className="opacity-60 w-full block bg-indigo-500 hover:bg-indigo-400 focus:bg-indigo-400 text-white font-semibold rounded-lg px-4 py-3 mt-6"
                                disabled
                            >Logging In...
                                <div className="spinner-border ml-2 w-5 h-5" role="status" />
                            </button>
                            :
                            <button
                                type="submit"
                                className="w-full block bg-indigo-500 hover:bg-indigo-400 focus:bg-indigo-400 text-white font-semibold rounded-lg px-4 py-3 mt-6"
                            >Log In</button>
                        }
                    </form>

                    <hr className="my-6 border-gray-300 w-full" />

                    <p className="mt-8 pb-3">Need an account? <Link to="/register" className="text-blue-500 hover:text-blue-700 font-semibold">Create an account</Link></p>


                </div>
            </div>

        </section >
    )
}
