import React from 'react'
import { FaEdit } from "react-icons/fa";
import { FaRegTrashAlt } from "react-icons/fa";
import Button from 'react-bootstrap/Button';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import AddCategoryForm from './AddCategoryForm';
import { useEffect } from 'react';
import axios from 'axios';
import { product_api } from '../../../Utils/constants';
import toast from 'react-hot-toast'
import { deleteFirebaseImage } from '../../../Utils/FirebaseStorage';
import { useContext } from 'react';
import { AppContext } from '../../../Context/Context';
import BootstrapToast from '../../BootstrapToast/BootstrapToast';
import { filterData } from '../../../Utils/FilterData';
import SearchBox from '../../../Utils/SearchBox';

export default function CategoryList() {
  const {jwt} = useContext(AppContext)
  
  const fetchData = async() => {
    await axios.get(`${product_api}/get_categories`)
    .then(function (res) {
      setData(res.data.data)
    })
    .catch(() => toast.error("Some Error while fetching data !"))
  }

  const [currentData, setCurrentData] = useState()

  //Update Modal
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const handleCloseUpdateModal = () => setShowUpdateModal(false);
  const handleCloseUpdateModalFetchData = () => {setShowUpdateModal(false);fetchData()}
  const handleShowUpdateModal = () => setShowUpdateModal(true);

  //Delete Modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const handleCloseDeleteModalFetchData = () => {setShowDeleteModal(false);fetchData()}
  const handleShowDeleteModal = () => setShowDeleteModal(true);

  const [data, setData] = useState([])
  useEffect(() => {
    fetchData()
  },[])

  //Searchbox
  const [searchItem, setSearchItem] = useState('')
  const [filteredData, setFilteredData] = useState([])
  useEffect(() => {
    if (searchItem)
      setFilteredData(filterData(searchItem, data))
    else
      setFilteredData([])
    // eslint-disable-next-line
  }, [searchItem])


  //Delete a category
  const [loading, setLoading] = useState(false)
  const handleDeleteCategory = async(data) => {
    setLoading(true)
    await axios.post(`${product_api}/delete_category`,
    {id:data.id},
    {headers: {Authorization: `Bearer ${jwt}`}})
    .then(async()=>{
      await deleteFirebaseImage(data.image_url,"categoryImages")
      toast.success("Deleted Category !")
      handleCloseDeleteModalFetchData()
    })
    .catch((error)=>toast.error("Server Error !"))
    .finally(()=> setLoading(false))
  }

  return (
    <div>
      {loading && <BootstrapToast />}
      <p className='text-2xl font-medium mb-6'>Available Categories</p>
      <SearchBox setSearchItem={setSearchItem} />
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Category</th>
            <th scope="col">Image</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {
            searchItem?
            filteredData.map((data,index) => (
              <tr key={data.id}>
                <th scope="row">{index}</th>
                <td>{data.name}</td>
                <td><a href={data.image_url} target='_blank' rel="noreferrer" className='text-blue'>Click here</a></td>
                <td>
                  <button className='btn btn-outline-primary' onClick={()=>{handleShowUpdateModal();setCurrentData(data)}}><FaEdit /></button>&ensp;
                  <button className='btn btn-outline-danger' onClick={()=>{handleShowDeleteModal();setCurrentData(data)}}><FaRegTrashAlt /></button>
                </td>
              </tr>
            ))
            :
            data.map((data,index) => (
              <tr key={data.id}>
                <th scope="row">{index}</th>
                <td>{data.name}</td>
                <td><a href={data.image_url} target='_blank' rel="noreferrer" className='text-blue'>Click here</a></td>
                <td>
                  <button className='btn btn-outline-primary' onClick={()=>{handleShowUpdateModal();setCurrentData(data)}}><FaEdit /></button>&ensp;
                  <button className='btn btn-outline-danger' onClick={()=>{handleShowDeleteModal();setCurrentData(data)}}><FaRegTrashAlt /></button>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
      <Modal show={showUpdateModal} onHide={handleCloseUpdateModal} size="lg">
        <Modal.Header>
          <Modal.Title className='w-full flex flex-row justify-between items-center'>
            <p>Update Category</p>
            <button onClick={handleCloseUpdateModal}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
              </svg>
            </button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddCategoryForm type="edit" data={currentData} closeModal={handleCloseUpdateModalFetchData} />
        </Modal.Body>
      </Modal>

      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header>
          <Modal.Title className='w-full flex flex-row justify-between items-center'>
            <p>Delete Category</p>
            <button onClick={handleCloseDeleteModal}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
              </svg>
            </button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='font-sans'>Are you sure?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={handleCloseDeleteModal}>
            No
          </Button>
          <Button variant="outline-danger" onClick={()=>handleDeleteCategory(currentData)} disabled={loading}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  )
}
