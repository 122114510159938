import React, { useContext, useEffect, useRef, useState } from 'react'
import './NavigationBar.css'
import { FaRegUser } from "react-icons/fa";
import { Link } from 'react-router-dom'
import { AppContext } from '../../Context/Context'
import { AiOutlineHeart, AiOutlineSearch, AiOutlineShoppingCart } from 'react-icons/ai'
import ProfileDropdown from './ProfileDropdown'
import { home_api } from '../../Utils/constants';
import axios from 'axios'
import Carousel from "react-multi-carousel";
import Loader from '../Loader/Loader';
import { LeftArrow, RightArrow } from '../../Utils/Arrow'
import SearchModalBody from './SearchModalBody'
import Modal from 'react-bootstrap/Modal'

export default function NavigationBar() {
    const { cart, wishlist, user, logout, isAuthorized, categories } = useContext(AppContext)

    //fetch logo
    const [logo, setLogo] = useState('')
    useEffect(() => {
        axios.get(`${home_api}`)
            .then(function (response) {
                if (response.data) {
                    // console.log(response.data.data.logo)
                    setLogo(response.data.data.logo)
                }
            })
    }, [])

    //Drop down menu
    const [toggleHover, setToggleHover] = useState(false);
    const dropdownRef = useRef(null);
    const handleMouseEnter = () => {
        setToggleHover(true);
    };
    const handleMouseLeave = () => {
        setToggleHover(false);
    };
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
        const { carouselState: { currentSlide } } = rest;
        return (
            <div className="absolute top-0 right-0 flex gap-2">
                <div className={currentSlide === 0 ? 'disable' : ''} onClick={() => previous()} ><LeftArrow /></div>
                <div onClick={() => next()} > <RightArrow /> </div>
            </div>
        );
    };

    //Search Modal
    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => {
        document.getElementById('search-box').blur()
        setShowModal(true)
    }

    const SearchModal = () => {
        return (
            <Modal show={showModal} onHide={handleCloseModal} size='lg'>
                <Modal.Header>
                    <Modal.Title className='w-full flex flex-row justify-end items-center'>
                        <button onClick={handleCloseModal}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SearchModalBody closeModal={handleCloseModal} />
                </Modal.Body>
            </Modal>
        )
    }

    //Get Current Screen size
    const [screenSize, setScreenSize] = useState(getCurrentDimension());
    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }
    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);
        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize])


    if (screenSize.width >= 700) return (
        // bg-[#FAF3E6]
        <div className='sticky top-0 navigation-top z-[1050] bg-white shadow-sm'>
            <div className='relative'>
                <div className='px-5 pt-2 pb-1 flex flex-row gap-1 justify-between items-center'>
                    <Link to="/"><img src={logo} alt="Annapurna Sweets & Fast Food Resturant" className='w-36' /></Link>
                    <div className='flex flex-col gap-3'>
                        <div className='flex flex-row gap-3 items-center justify-end'>
                            <div className="input-group w-[30vw]">
                                <input type="text" id="search-box" className="form-control rounded-[30px]  url-text-box" placeholder="Search" onFocus={handleShowModal} onClick={handleShowModal} />
                                <button className="bg-theme-red px-2 text-white text-[20px] rounded-[30px]"><AiOutlineSearch /></button>
                            </div>
                            {/* <div>|</div> */}
                            <div>
                                <Link to="/cart">
                                    <div className="relative">
                                        <div className="-top-2 absolute left-5">
                                            <p className="flex h-1 w-1 items-center justify-center rounded-full bg-theme-orange-dark p-[12px] text-sm text-white">{cart.length}</p>
                                        </div>
                                        <AiOutlineShoppingCart className='w-7 h-8' />
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className='flex flex-row gap-5 items-center'>
                            <ul className='nav-items'>
                                <Link to="/"><li>Home</li></Link>
                                <Link
                                    to="/products"
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                    onClick={handleMouseLeave}
                                >
                                    <li>Shop</li>
                                </Link>
                                <Link to="/about"><li>About Us</li></Link>
                            </ul>
                            <Link to="/wishlist">
                                <div className="relative">
                                    <div className="-top-2 absolute left-5">
                                        <p className="flex h-1 w-1 items-center justify-center rounded-full bg-theme-orange-dark p-[12px] text-sm text-white">{wishlist.length}</p>
                                    </div>
                                    <AiOutlineHeart className='w-7 h-8' />
                                </div>
                            </Link>
                            {/* {!isAuthorized ? <Link to="/login"><FaRegUser className='w-5 h-7' /></Link> : <></>} */}

                            {isAuthorized ? <ProfileDropdown image={user.profile_url} email={user.email} logout={logout} /> : <Link to="/login"><FaRegUser className='w-5 h-7' /></Link>}
                        </div>
                    </div>
                </div>

                {/* Shop Categories */}
                <div className={`w-full h-fit absolute bg-white ${toggleHover ? "block" : "hidden"} -mt-3 min-h-56`}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    ref={dropdownRef}>
                    <div className='w-full bg-transparent' />
                    <div className='w-full px-8 py-1 mb-3 flex flex-row items-center'>
                        <div className='w-[30%]'>
                            <p className='text-3xl'>Shop</p><br />
                            <p>Dive into the delicious world of our sweet shop and discover amazing treats in different categories. </p>
                        </div>
                        <div className='w-[70%]'>
                            {!categories.length ? <Loader />:
                                <div className='relative'>
                                    <Carousel arrows={false} responsive={responsive} renderButtonGroupOutside={true} customButtonGroup={<ButtonGroup />} ssr={true}>
                                        {categories.map(category => (
                                            <Link key={category.id} to={`/products#${category.name.replace(" ", "_")}`} onClick={handleMouseLeave}>
                                                <div className='mt-10 mb-1 py-1 px-3 mx-3 ring-2 border rounded-lg ring-theme-orange-dark flex flex-col items-center justify-center gap-2'>
                                                    <img src={category.image_url} alt="Category" className='h-48' />
                                                    <div className='w-full flex flex-row justify-between'>
                                                        <p className='text-lg'>{category.name}</p>
                                                        <RightArrow />
                                                    </div>
                                                </div>
                                            </Link>
                                        ))}
                                    </Carousel>
                                </div>
                            }
                        </div>
                    </div>
                    <div className='absolute bottom-0 w-full h-1 bg-theme-orange-dark' />
                </div>
                <div className='w-full h-1 bg-theme-orange-dark' />
            </div>
            <SearchModal />
        </div>

    )

    //Mobile top bar
    else if (screenSize.width < 700) return (
        <div className='sticky top-0 z-[1050] shadow-sm'>
            <div className='flex flex-row justify-between items-center px-2 py-1 bg-white'>
                <Link to="/"><img src={logo} alt="Annapurna Sweets & Fast Food Resturant" className='p-1 w-28' /></Link>

                <div className='flex flex-row gap-3 items-center'>
                    <Link to="/wishlist">
                        <div className="relative flex right-0">
                            <div className="top-1 absolute left-4">
                                <p className="flex h-1 w-1 items-center justify-center rounded-full bg-theme-orange-dark p-[9px] text-xs text-white">{wishlist.length}</p>
                            </div>
                            <AiOutlineHeart className='mt-2 w-7 h-8' />
                        </div>
                    </Link>
                    {isAuthorized ? <ProfileDropdown image={user.profile_url} email={user.email} logout={logout} /> : <Link to="/login"><FaRegUser className='w-5 h-7' /></Link>}
                </div>
            </div>
            <div className='w-full h-1 bg-theme-orange-dark' />
        </div>
    )
}
