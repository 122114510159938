import React, { useEffect } from 'react'
import { useState } from 'react'
import { order_api } from '../../../Utils/constants'
import toast from 'react-hot-toast'
import axios from 'axios'
import Modal from 'react-bootstrap/Modal'
import { useContext } from 'react'
import { AppContext } from '../../../Context/Context'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { FaRegTrashAlt } from 'react-icons/fa'
import BootstrapToast from '../../BootstrapToast/BootstrapToast'

export default function SubscribedEmails() {
  const { jwt } = useContext(AppContext)

  const [data, setData] = useState([])
  const [selectedData, setSelectedData] = useState()

  const fetchData = async () => {
    await axios.get(`${order_api}/subscribed_email`, { headers: { Authorization: `Bearer ${jwt}` } })
      .then(function (res) {
        setData(res.data.data)
      })
      .catch(() => toast.error("Some Error while fetching data !"))
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line
  }, [])

  //Add/Update Modal
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const handleCloseUpdateModal = () => setShowUpdateModal(false);
  const handleCloseUpdateModalFetchData = () => { setShowUpdateModal(false); fetchData() }
  const handleShowUpdateModal = () => setShowUpdateModal(true);

  //Delete Modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const handleCloseDeleteModalFetchData = () => { setShowDeleteModal(false); fetchData() }
  const handleShowDeleteModal = () => setShowDeleteModal(true);

  //Delete Subscribed Email
  const [loading, setLoading] = useState(false)
  const handleDeleteSubscribedEmail = async (data) => {
    console.log(data)
    setLoading(true)
    await axios.delete(`${order_api}/subscribed_email`,
    { 
      headers: { Authorization: `Bearer ${jwt}` }, 
      data: {id:data.id}
    })
    .then(() => {
        toast.success("Deleted Subscribed Email !")
        handleCloseDeleteModalFetchData()
    })
    .catch(()=> toast.error("Server Error !"))
    .finally(() => setLoading(false))
  }

  //Form
  const [subscribedEmail, setSubscribedEmail] = useState('')
  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    axios.post(`${order_api}/subscribed_email`, {
      email: subscribedEmail
    }, { headers: { Authorization: `Bearer ${jwt}` } })
      .then(() => {
        toast.success("Subscribed Email Added !")
        handleCloseUpdateModalFetchData()
      })
      .catch(()=> toast.error("Server Error !"))
      .finally(() => { setLoading(false); setSubscribedEmail('') })
  }

  return (
    <div>
      <p className='text-2xl font-medium'>Subscribed Emails</p>
      <div className='w-full flex justify-end mb-10'>
        <button className='btn btn-primary' onClick={handleShowUpdateModal}>Add</button>
      </div>
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Email</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {
            data.map((data, index) => (
              <tr key={data.id}>
                <th scope="row">{index + 1}</th>
                <td>{data.email}</td>
                <td>
                  {/* <button className='btn btn-outline-primary' onClick={() => { handleShowUpdateModal(); setCredentials(data) }}><FaEdit /></button>&ensp; */}
                  <button className='btn btn-outline-danger' onClick={() => { handleShowDeleteModal(); setSelectedData(data) }}><FaRegTrashAlt /></button>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>



      <Modal show={showUpdateModal} onHide={handleCloseUpdateModal} size="lg">
        <Modal.Header>
          <Modal.Title className='w-full flex flex-row justify-between items-center'>
            <p>Add Subscribed Email</p>
            <button onClick={handleCloseUpdateModal}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
              </svg>
            </button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} className={`font-sans ${loading ? 'opacity-50' : ''}`}>

            <Form.Group className="mb-4">
              <Form.Label className='font-medium'>Email</Form.Label>
              <Form.Control placeholder="Enter Email" type="email" name="email" onChange={(e) => setSubscribedEmail(e.target.value)} value={subscribedEmail} required />
            </Form.Group>

            <hr className="!my-5" />
            <div className='text-center'>
              <button className="bg-red-500 p-2 rounded-lg text-white" disabled={loading}>
                Add Email
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header>
          <Modal.Title className='w-full flex flex-row justify-between items-center'>
            <p>Delete Subscribed Email</p>
            <button onClick={handleCloseDeleteModal}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
              </svg>
            </button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='font-sans'>Are you sure?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={handleCloseDeleteModal}>
            No
          </Button>
          <Button variant="outline-danger" onClick={() => handleDeleteSubscribedEmail(selectedData)} disabled={loading}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      {loading && <BootstrapToast />}
    </div>
  )
}
