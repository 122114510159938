import React from 'react'
import Loader from '../../Components/Loader/Loader'
import { useContext } from 'react'
import { AppContext } from '../../Context/Context'
import ProductCard from '../../Components/ProductCard/ProductCard'

export default function OfferProducts() {
    const {products} = useContext(AppContext)
    return (
        <div className='products p-[12px] lg:p-8'>
            <p className='ml-5 md:ml-16 mb-6 uppercase fs-2 text-theme-red-dark'>
                OFFERS
            </p>
            {!products ? <Loader /> :
                <div className='flex flex-wrap gap-3 md:!gap-5 justify-center'>
                    {products.map(product => (
                        product.offer &&
                        <div key={product.id}>
                            <ProductCard item={product} />
                        </div>
                    ))}
                </div>
            }

        </div>
    )
}
