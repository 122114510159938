import React, { useContext, useState } from 'react'
import { AppContext } from '../../Context/Context'
import { useNavigate } from 'react-router-dom'

export default function DeleteAccount() {
    const [step, setStep] = useState(1)
    const [checked, setChecked] = useState(false)

    const {jwt} = useContext(AppContext)
    const navigate = useNavigate()

    if(jwt)
    return (
        <div className='w-full h-fit py-14 px-[18px] md:px-24 flex flex-col gap-3'>
            {step===1?
            <>
                <p className="text-xl md:text-2xl fw-bold">Are your sure to delete your account?</p>
                <label><input type='checkbox' checked={checked} onChange={() => setChecked(!checked)} /> Yes I agree</label>
                <button className='btn btn-danger w-fit' onClick={()=>setStep(2)} disabled={!checked}>Delete Account</button>
            </>
            :
            <p className="text-center text-xl md:text-2xl fw-bold py-5">Account deletion process started.<br/> We will soon delete your account!</p>
            }
        </div>
    )
    else
    return(
        <div className='w-full h-fit py-14 px-[18px] md:px-24 flex flex-col gap-3'>    
            <p className="text-xl md:text-2xl fw-bold">Login to Continue</p>
            <button className='btn btn-success w-fit' onClick={()=>navigate('/login')}>Login</button>
        </div>
    )
}
