import React from 'react';
import { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import ScrollToTop from '../../Components/ScrollToTop'
import axios from 'axios';
import { account_api } from '../../Utils/constants'

export default function OTP({ setStep, token }) {

    const [otp, setOtp] = useState('');
    const [mssg, setMssg] = useState('');
    const [loading, setLoading] = useState(false)

    //OTP Countdown
    const [trial, setTrial] = useState(1)
    const [seconds, setSeconds] = useState(1)
    const [minutes, setMinutes] = useState(0)

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1)
            }

            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval)
                }
                else {
                    setSeconds(59)
                    setMinutes(minutes - 1)
                }
            }
        }, 1000)

        return () => {
            clearInterval(interval)
        }
    }, [seconds, minutes])

    function resendOTP() {
        // e.preventDefault()
        setMssg('')
        if (trial === 1) {
            setMinutes(0)
            setSeconds(59)
        }
        else if (trial === 2) {
            setMinutes(1)
            setSeconds(30)
        }
        setTrial(trial + 1)
        axios.post(`${account_api}/resend_otp`, {}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(function (response) {
                console.log(response)
            })
            .catch(function (error) {
                setMssg("Couldnot resend OTP! Try again later")
            })
    }

    const Countdown = () => {
        if (seconds > 0 || minutes > 0) {
            return (
                <span>Resend OTP in {minutes < 10 ? `0${minutes}` : minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds}</span>
            )
        }

        else {
            if (trial <= 2) {
                return (
                    <button className='underline cursor-pointer' onClick={() => resendOTP()}>Re-send OTP</button>
                )
            }
            else {
                return (
                    <span>Maximum OTP Limit Reached. Try again later !</span>
                )
            }
        }
    }

    //OTP invalid
    const [invalidOTP, setInvalidOTP] = useState('')

    useEffect(() => {
        setInvalidOTP('')
    }, [otp])

    //Handle OTP submission
    const handleSubmit = (e) => {
        e.preventDefault();
        setMssg('')
        if (!otp) {
            setInvalidOTP("Please type your otp!")
            return
        }
        setLoading(true)
        axios.post(`${account_api}/verify_otp`, {
            'otp': Number(otp)
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(function (response) {
                if (response.data.success) {
                    setLoading(false)
                    setStep(3)
                }
            })
            .catch(function (error) {
                // console.log(error)
                if (error.response.status === 400) {
                    // console.log(error)
                    setMssg(error.response.data.message)
                }
                else {
                    setMssg("Internal Server Error occurred! Try again later.")
                }
                setLoading(false)
            })
    }

    return (
        <>
            <ScrollToTop />
            <div className='p-3'>
                <div className="flex flex-col justify-center items-center">
                    <p className="text-2xl font-semibold mt-3 mb-3">Enter otp sent to your email</p>

                    <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={5}
                        renderSeparator={<span>&emsp;&emsp;</span>}
                        renderInput={(props) => <input {...props} />}
                        inputStyle={`!w-[2.5em] bg-[#C9D7EE] fs-4 mt-8 mb-6 ${invalidOTP ? 'ring-[2px] ring-red-500 dark:bg-black dark:ring-[2px] dark:ring-red-500' : 'ring-[1px] ring-lightpurple focus:border-sky-200 focus:ring-sky-200 focus:ring-1 dark:bg-black dark:ring-1 dark:ring-[#ffffffCC]'} rounded-[10px] focus:outline-none h-12 text-center`}
                    />

                    {invalidOTP ?
                        <span className="mt-3 text-sm text-red-500">
                            {invalidOTP}
                        </span>
                        : <></>
                    }
                    <button className='btn btn-primary btn-lg mt-5' onClick={handleSubmit} disabled={loading}>
                        {loading?<>Verifying...<div class="spinner-border fs-6 ml-2 w-5 h-5" role="status"/></>:<>Verify</>}
                    </button>
                </div>
                <p className="mt-5 text-red-500 text-center">
                    {mssg}
                </p>
                <p className="mt-5 text-red-500 text-center">
                    <Countdown />
                </p>

            </div>
        </>
    )
}