import React from 'react'
// import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai'
import './CartQuantityToggle.css'
import { FaMinus, FaPlus } from "react-icons/fa";

export default function CartQuantityToggle({ quantity, setDecrease, setIncrease }) {
    return (
        <div className='d-flex flex-row text-md lg:text-lg'>
            <div className=''>Quantity:&ensp;</div>
            <div className='border-2 rounded-lg text-white bg-theme-red !border-theme-red text-md w-fit'>
                <button className='px-1' onClick={setDecrease}>
                    <FaMinus className='w-3 h-3' />
                </button>
                <span className='px-2'>{quantity}</span>
                <button className='px-1' onClick={setIncrease}>
                    <FaPlus className='w-3 h-3' />
                </button>
            </div>
            {/* <button className='quantity-toggle' onClick={setDecrease}><AiOutlineMinus /></button>
            <span className='font-sans'>&nbsp;&nbsp;{quantity}&nbsp;&nbsp;</span>
            <button className='quantity-toggle' onClick={setIncrease}><AiOutlinePlus /></button> */}
        </div>
    )
}
