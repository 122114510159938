import React from 'react'
import AddProductForm from './AddProductForm';


export default function AddProduct() {
    return (
        <>
            <p className='text-2xl font-medium mb-10'>Add Products</p>
            <AddProductForm type="add"/>
        </>
    )
}
