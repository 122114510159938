import React, { useState } from 'react'
import ImageUploading from 'react-images-uploading';
import { FaDownload } from "react-icons/fa";
import { CiCircleRemove } from "react-icons/ci";
import toast from 'react-hot-toast';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import axios from 'axios'
import { useEffect } from 'react';
import { product_api } from '../../../Utils/constants';
import { deleteFirebaseImage, uploadFirebaseImage } from '../../../Utils/FirebaseStorage';
import { useContext } from 'react';
import { AppContext } from '../../../Context/Context';
import BootstrapToast from '../../BootstrapToast/BootstrapToast';


export default function AddProductForm({ type, data, closeModal }) {

    const {jwt} = useContext(AppContext)

    const [credentials, setCredentials] = useState({
        id: type==="edit"?data.id:'',
        name: type==="edit"?data.name:'', 
        category: type==="edit"?data.category.id:'', 
        actualPrice: type==="edit"?data.actual_price:'', 
        discountedPrice: type==="edit"?data.discounted_price:'', 
        unit: type==="edit"?data.unit:'', 
        description: type==="edit"?data.description:'', 
        images:type==="edit"?data.images:[], 
        isFeatured: type==="edit"?data.is_featured:false, 
        isOffer: type==="edit"?data.is_offer:false, 
        rating: type==="edit"?data.rating:0
    })
    const onChange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value });
    }

    //fetch available categories
    const [categories, setCategories] = useState([])
    useEffect(()=>{
        axios.get(`${product_api}/get_categories`)
        .then(function(res){
            setCategories(res.data.data)
        })
    },[])

    //Images
    const [images, setImages] = React.useState([]);
    const maxNumber = 4;
    const onImageUpload = (imageList, addUpdateIndex) => {
        // data for submit
        console.log(imageList, addUpdateIndex);
        setImages(imageList);
    };

    //Delete images for edit
    const [deletedImageUrl, setDeletedImageUrl] = useState([])
    const onStorageImageRemove = (e,image) => {
        e.stopPropagation()
        setDeletedImageUrl([...deletedImageUrl,image])
        setCredentials({...credentials,images:credentials.images.filter(data => data !== image)})
    }

    useEffect(()=>{
        console.log(credentials)
    },[credentials])

    useEffect(()=>{
        console.log(deletedImageUrl)
    },[deletedImageUrl])


    const [loading, setLoading] = useState(false)
    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log(images)
        // console.log(credentials.image)
        if(!images.length && !credentials.images.length){
            toast.error("One image must be selected !")
            return;
        }
        setLoading(true)
        let image_url = await uploadFirebaseImage(images,"productImages")
        let data = {
            name: credentials.name,
            category_id: credentials.category,
            actual_price: credentials.actualPrice,
            discounted_price: credentials.discountedPrice,
            unit: credentials.unit,
            is_featured: credentials.isFeatured,
            is_offer: credentials.isOffer,
            description: credentials.description,
            rating: credentials.rating,
            images: image_url
        }
        if(type==="edit"){
            if(deletedImageUrl.length){
                deletedImageUrl.map(async(image) => await deleteFirebaseImage(image,"productImages"))
                setDeletedImageUrl([])
            }
            data['id'] = credentials.id
            let prevImages = credentials.images.slice(0).reverse().map(element => {return element;});
            prevImages.map(image=>data.images.unshift(image))
            axios.post(`${product_api}/update_product`,data, {headers: {Authorization: `Bearer ${jwt}`}})
            .then(()=>{
                toast.success("Updated Product !")
                closeModal()
                setImages([])
            })
            .catch((e)=> console.log(e))
            .finally(()=> setLoading(false))
            return;
        }
        axios.post(`${product_api}/create_product`,data, {headers: {Authorization: `Bearer ${jwt}`}})
        .then(()=>{
            // console.log(res)
            toast.success("Product Added !")
            setCredentials({name: '', category: '', actualPrice: '', discountedPrice: '', unit: '', description: '', images:[], isFeatured: false, isOffer: false, rating: 0})
            setImages([])
        })
        .catch((e)=> toast.error("Server Error !"))
        .finally(()=> setLoading(false))
    }

    return (
        <Form className={`font-sans ${loading?'opacity-50':''}`} onSubmit={handleSubmit}>

            <Form.Group className="mb-4">
                <Form.Label className='font-medium'>Product Name</Form.Label>
                <Form.Control placeholder="Enter Product Name" name="name" onChange={onChange} value={credentials.name} required />
            </Form.Group>

            <Row className="mb-4">
                <Form.Group as={Col}>
                    <Form.Label className='font-medium'>Unit</Form.Label>
                    <Form.Control type="text" placeholder="Enter unit" name="unit" onChange={onChange} value={credentials.unit} required />
                </Form.Group>

                <Form.Group as={Col}>
                    <Form.Label className='font-medium'>Category</Form.Label>
                    <Form.Select onChange={onChange} name="category" value={credentials.category} required>
                        <option value="">Choose category</option>
                        {categories.map(category=>(
                            <option value={category.id} key={category.id}>{category.name}</option>
                        ))}
                    </Form.Select>
                </Form.Group>
            </Row>


            <Row className="mb-4">
                <Form.Group as={Col}>
                    <Form.Label className='font-medium'>Actual Price</Form.Label>
                    <Form.Control placeholder="Actual Price" name="actualPrice" onChange={onChange} value={credentials.actualPrice} required />
                </Form.Group>

                <Form.Group as={Col}>
                    <Form.Label className='font-medium'>Discounted Price</Form.Label>
                    <Form.Control placeholder="Discounted Price" name="discountedPrice" onChange={onChange} value={credentials.discountedPrice} required />
                </Form.Group>

            </Row>

            <Form.Group className='mb-4'>
                <Form.Check type='checkbox'>
                    <Form.Check.Input type='checkbox' onChange={()=>setCredentials({...credentials, isFeatured: !credentials.isFeatured})} checked={credentials.isFeatured}/>
                    <Form.Check.Label className='font-medium'>Add product to Featured</Form.Check.Label>
                </Form.Check>
            </Form.Group>

            <Form.Group className='mb-4'>
                <Form.Check type='checkbox'>
                    <Form.Check.Input type='checkbox' onChange={()=>setCredentials({...credentials, isOffer: !credentials.isOffer})} checked={credentials.isOffer}/>
                    <Form.Check.Label className='font-medium'>Add product to Offer</Form.Check.Label>
                </Form.Check>
            </Form.Group>

            <Form.Group className='mb-4'>
                <Form.Label className='font-medium'>Description</Form.Label>
                <Form.Control as="textarea" rows={3} name="description" value={credentials.description} onChange={onChange}/>
            </Form.Group>

            <Form.Group className="mb-4 w-full">
                <Form.Label className='font-medium'>Upload Images</Form.Label>
                <ImageUploading
                    multiple
                    value={images}
                    onChange={onImageUpload}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                >
                    {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                        errors
                    }) => (
                        // write your building UI
                        <div className="upload__image-wrapper">
                            <div
                                style={isDragging ? { opacity: '0.5' } : undefined}
                                className={`w-full h-80 bg-[#F2F2F2] ring-1 ring-black rounded-xl flex ${imageList.length || credentials.images.length ? 'flex-wrap' : 'justify-center items-center flex-col'} gap-3`}
                                onClick={onImageUpload}
                                {...dragProps}
                            >
                                {!imageList.length && !credentials.images.length ?
                                    <>
                                        <FaDownload className='w-10 h-10' />
                                        <p>Click or Drop here</p>
                                    </>
                                    :
                                    <>
                                        {
                                            credentials.images.length &&
                                            credentials.images.map((image, index) => (
                                                <div key={index} className="flex flex-col p-2 gap-2">
                                                    <img src={image} alt="" width="100" className='rounded-xl' />
                                                    <div className="flex justify-center w-full">
                                                        <button onClick={(e) => onStorageImageRemove(e,image)} className='w-full flex justify-center'>
                                                            <CiCircleRemove className='w-6 h-6' />
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                        {imageList.map((image, index) => (
                                            <div key={index} className="flex flex-col p-2 gap-2">
                                                <img src={image['data_url']} alt="" width="100" className='rounded-xl' />
                                                <div className="flex justify-center w-full">
                                                    <button onClick={(e) => {e.stopPropagation();onImageRemove(index)}} className='w-full flex justify-center'>
                                                        <CiCircleRemove className='w-6 h-6' />
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                }
                            </div>
                            {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
                            {errors && <div className='hidden'>
                                {errors.maxNumber && toast.error('Only 4 images allowed !', { id: 'maxnumber' })}
                                {errors.acceptType && toast.error("Only images can be uploaded !", { id: 'type' })}
                                {errors.maxFileSize && toast.error("File size too large !", { id: 'filesize' })}
                            </div>
                            }
                        </div>
                    )}
                </ImageUploading>
            </Form.Group>
            {/* <Form.Group className="mb-4">
                <Form.Label className='font-medium'>Initial Rating</Form.Label>
                <Form.Control type='number' name="rating" onChange={onChange} value={credentials.rating} max="5" />
            </Form.Group> */}
            <hr className="!my-5" />
            <div className={`${type === "add" ? '' : 'text-center'}`}>
                <button className="bg-red-500 p-2 rounded-lg text-white" type="submit" disabled={loading} >
                    {type === "add" ? 'Add Product' : 'Update Product'}
                </button>
            </div>

            {loading && <BootstrapToast />}
        </Form>
    )
}
