import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { AppContext } from '../../Context/Context'
import { RightArrow } from '../../Utils/Arrow'

export default function Categories() {
  const { categories } = useContext(AppContext)

  const [viewAll, setViewAll] = useState(false)
  const [slicedCategories, setSlicedCategories] = useState([])

  useEffect(()=>{
    if(viewAll)
      setSlicedCategories(categories.slice(0,categories.length))
    else{
      if(window.innerWidth > 600 && window.innerWidth < 700)
        setSlicedCategories(categories.slice(0,3))
      else if(window.innerWidth > 700)
        setSlicedCategories(categories.slice(0,4))
      else
        setSlicedCategories(categories.slice(0,2))
    }
  },[viewAll, categories])

  return (
    <div className='mt-5 block lg:hidden'>
      <div className='bg-[#F0DFC6] h-fit px-4 md:px-10 pt-4 pb-24'>
        <p className='text-[#AA2637] text-2xl uppercase'>Discover Our<br /> Complete Range</p>
        <p className='text-[#AA2637] text-xs my-2'>Dive into the delicious world of our sweet shop and discover amazing treats in different categories.</p>
        <div className='flex flex-row justify-end cursor-pointer' onClick={()=>setViewAll(!viewAll)}>
          <p className="text-base text-theme-red underline">{viewAll?'View Less':' View All'}</p>
        </div>
      </div>
      <div className='flex flex-wrap items-center justify-center gap-3 -mt-20 py-1 px-2 md:px-10'>
        {slicedCategories.map(category => (
          <Link key={category.id} to={`/products#${category.name.replace(" ", "_")}`}>
            <div className='w-fit py-1 px-3 ring-2 border rounded-lg ring-theme-orange-dark flex flex-col items-center justify-center gap-2'>
              <img src={category.image_url} alt="Category" className='w-28 h-28 object-contain' />
              <div className='w-full flex flex-row items-center justify-between'>
                <p className='text-base'>{category.name}</p>
                <RightArrow size="lg" />
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  )
}
