import React from 'react'
import './ProductImageViewer.css'
import ImageZoom from "react-image-zooom";
import { useState } from 'react';

export default function ProductImageViewer({images}) {

    // const image = '/assets/3.jpg'
    const [imageUrl,setImageUrl] = useState(images[0])

    return(
        <div className='grid grid-cols-12'>
            <div className='col-span-2 flex flex-col justify-center items-center gap-2 pr-2'>
                {images.map(image=>(
                    <img key={image} src={image} alt="Product Images" className='img-fluid border-2 border-black cursor-pointer max-h-full' onMouseOver={()=>setImageUrl(image)}/>
                ))}
            </div>
            <div className='col-span-10'>
        <ImageZoom src={imageUrl} alt="A image to apply the ImageZoom plugin" zoom="200" className="rounded-2xl"/>
            </div>
        </div>

    )
}
