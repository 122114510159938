import React from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
// import Rating from '../../Components/Rating/Rating'
import CardQuantityToggle from '../../Components/CartQuantityToggle/CartQuantityToggle'
import { AiOutlineHeart } from 'react-icons/ai'
// import RateItem from '../../Components/Rating/RateItem'
import Loader from '../../Components/Loader/Loader'
import AddToCart from '../../Components/AddToCart'
import { AppContext } from '../../Context/Context'
import { useContext } from 'react'
import AddToWishlist from '../../Components/AddToWishlist'
import ProductImageViewer from '../../Components/ProductImageViewer/ProductImageViewer'

export default function ProdcutDetails() {

  //Read url for parameter
  const { productSlug } = useParams()
  // console.log(productName)

  //Fetch cart and wishlist
  const { cart, wishlist, products } = useContext(AppContext)

  //fetch product having productName
  const product = products.find(p => p.slug === productSlug)
  // console.log(product)

  //Qunatity toggler
  const [quantity, setQuantity] = useState(1)
  const setDecrease = () => {
    quantity > 1 ? setQuantity(quantity - 1) : setQuantity(1);
  }
  const setIncrease = () => {
    setQuantity(quantity + 1);
  }

  //Rate an Item
  // const [rate, setRate] = useState(0)

  return (
    <div className='p-5 bg-[#FFFDF9]'>
      {!product ? <Loader /> :
        <>
          <div className='grid grid-cols-1 md:grid-cols-5 mt-2'>
            <div className='col-span-2 flex flex-row items-center justify-center max-h-96'>
              <ProductImageViewer images={product.images} />
            </div>
            <div className='col-span-3 flex flex-col gap-2 sm:pl-5'>
              <p className='fs-2'>{product.title}</p>
              {/* <Rating rating={product.rating} /> */}
              <p><span className='satoshi-regular fs-3 fw-bold'>Rs. {product.price}</span> <span className="fs-5 fw-bold text-slate-500">&ensp;per {product.unit}</span></p>
              <div className='mb-4 mt-3 sm:mb-0'>
                <CardQuantityToggle quantity={quantity}
                  setDecrease={setDecrease}
                  setIncrease={setIncrease} />
              </div>
              <div className='flex flex-row gap-3 items-center mb-3'>
                {cart.find((i) => i.id === product.id) ?
                  <button className='btn btn-outline-success w-full md:w-[40%] px-3 fs-5 h-12' disabled>Added to Cart</button>
                  :
                  <div className='w-full md:w-[40%]'>
                    <AddToCart item={product} quantity={quantity}>
                      <button className='btn btn-success w-full fs-5 px-3 h-12'>Add to Cart</button>
                    </AddToCart>
                  </div>
                }
                <AddToWishlist item={product} rating={product.rating.rate}>
                  <button className={`btn ${wishlist.find((i) => i.id === product.id) ? 'btn-danger' : 'btn-outline-danger'}`}><AiOutlineHeart className='w-8 h-8' /></button>
                </AddToWishlist>
              </div>
              <p className='fs-5'>Product Details</p>
                <pre className='font-coconat text-justify whitespace-pre-wrap'>{product.description}</pre>
                
            </div>
          </div>


          {/* <p className='mt-20 fs-1 text-orange fw-semibold text-center mb-4'>Rate this item</p>
          <RateItem rate={rate} setRate={setRate} disabled={!isAuthorized} id={product.id} token={jwt} />
          {isAuthorized ? <></> : <div className='fs-3 text-center mt-4 text-danger'>Login to rate this item !</div>} */}
        </>
      }
    </div>

  )
}
