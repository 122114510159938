import axios from 'axios'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { account_api } from '../../Utils/constants'
import toast from 'react-hot-toast'

export default function RegisterForm({ step, setStep, setToken, loginDetails, setLoginDetails }) {

    const [loading, setLoading] = useState(false)

    //Eror message if any error in form
    const [errorMssg, setErrorMssg] = useState('')

    //Form credentials
    const [credentials, setcredentials] = useState({ name: '', email: '', password: '', confirm_password: '' })
    const onChange = (e) => {
        setcredentials({ ...credentials, [e.target.name]: e.target.value })
        setLoginDetails({ ...loginDetails, [e.target.name]: e.target.value })
    }

    //Form Validation
    const [validateEmail, setValidateEmail] = useState('')
    const [validatePassword, setValidatePassword] = useState('')
    const [validateConfirmPassword, setValidateConfirmPassword] = useState('')
    useEffect(() => {
        //Email Error
        const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!credentials.email.match(validRegex)) {
            setValidateEmail('Email address not valid')
        }
        else {
            setValidateEmail('')
        }

        //Password Error
        if (credentials.password.length < 8 && credentials.password !== '') {
            setValidatePassword('Password must be at least 8 characters')
        }
        else {
            setValidatePassword('')
        }

        //Confirm Password Error
        if (credentials.confirm_password !== credentials.password) {
            setValidateConfirmPassword('Password do not match !')
        }
        else {
            setValidateConfirmPassword('')
        }

    }, [credentials.email, credentials.password, credentials.confirm_password])

    //on Form Submission
    const onSubmit = (e) => {
        e.preventDefault()
        setErrorMssg('')
        if (credentials.name === '' || credentials.email === '' || credentials.password === '' || credentials.confirm_password === '') {
            setErrorMssg('All fields are required!')
            return
        }
        if (validateEmail || validatePassword || validateConfirmPassword) {
            return;
        }
        setLoading(true)
        axios.post(`${account_api}/register`, {
            name: credentials.name,
            email: credentials.email,
            password: credentials.password
        })
            .then(function (response) {
                // console.log(response);
                if (response.data.success) {
                    setLoading(false)
                    setToken(response.data.token)
                    toast.success("Account Registered! Verify OTP.")
                    setStep(2)
                }
                else {
                    setErrorMssg(response.data.message)
                }
                setLoading(false)
            })
            .catch(function (error) {
                // console.log(error)
                if (error.response.status === 400) {
                    setErrorMssg(error.response.data.message)
                }
                else {
                    setErrorMssg('Internal Server Error! Try again later.')
                }
                setLoading(false)
            });

    }

    return (
        <div>
            <h1 class="text-2xl xl:text-3xl font-extrabold">
                Sign up
            </h1>
            <div class="w-full flex-1 mt-8">
                <div class="flex flex-col items-center">
                    <button
                        class="w-full max-w-xs font-bold shadow-sm rounded-lg py-3 bg-indigo-100 text-gray-800 flex items-center justify-center transition-all duration-300 ease-in-out focus:outline-none hover:shadow focus:shadow-sm focus:shadow-outline"
                    >
                        <div class="bg-white p-2 rounded-full">
                            <svg class="w-4" viewBox="0 0 533.5 544.3">
                                <path
                                    d="M533.5 278.4c0-18.5-1.5-37.1-4.7-55.3H272.1v104.8h147c-6.1 33.8-25.7 63.7-54.4 82.7v68h87.7c51.5-47.4 81.1-117.4 81.1-200.2z"
                                    fill="#4285f4"
                                />
                                <path
                                    d="M272.1 544.3c73.4 0 135.3-24.1 180.4-65.7l-87.7-68c-24.4 16.6-55.9 26-92.6 26-71 0-131.2-47.9-152.8-112.3H28.9v70.1c46.2 91.9 140.3 149.9 243.2 149.9z"
                                    fill="#34a853"
                                />
                                <path
                                    d="M119.3 324.3c-11.4-33.8-11.4-70.4 0-104.2V150H28.9c-38.6 76.9-38.6 167.5 0 244.4l90.4-70.1z"
                                    fill="#fbbc04"
                                />
                                <path
                                    d="M272.1 107.7c38.8-.6 76.3 14 104.4 40.8l77.7-77.7C405 24.6 339.7-.8 272.1 0 169.2 0 75.1 58 28.9 150l90.4 70.1c21.5-64.5 81.8-112.4 152.8-112.4z"
                                    fill="#ea4335"
                                />
                            </svg>
                        </div>
                        <span class="ml-4">
                            Sign Up with Google
                        </span>
                    </button>

                </div>

                <div class="my-12 border-b text-center">
                    <div
                        class="leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform translate-y-1/2"
                    >
                        Or sign up with e-mail
                    </div>
                </div>


                <form class="mx-auto max-w-xs" onSubmit={onSubmit}>
                    <input
                        class="w-full fs-5 px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                        type="text"
                        placeholder="Name"
                        name="name"
                        onChange={onChange}
                        value={credentials.name}

                    />
                    <label className="relative block w-full">
                        <input
                            class="w-full fs-5 px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 peer"
                            type="email"
                            placeholder="Email"
                            name="email"
                            onChange={onChange}
                            value={credentials.email}
                            required
                        />
                        {validateEmail ?
                            <span className="mt-4 hidden text-sm text-red-500 peer-[&:not(:placeholder-shown):not(:focus)]:block">
                                {validateEmail}
                            </span>
                            : <></>
                        }
                    </label>

                    <label className="relative block w-full">
                        <input
                            class="w-full fs-5 px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 peer"
                            type="password"
                            placeholder="Password"
                            name="password"
                            onChange={onChange}
                            value={credentials.password}
                            required
                        />
                        {validatePassword ?
                            <span className="mt-4 hidden text-sm text-red-500 peer-[&:not(:placeholder-shown):not(:focus)]:block">
                                {validatePassword}
                            </span>
                            : <></>
                        }
                    </label>

                    <label className="relative block w-full">
                        <input
                            class="w-full fs-5 px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 peer"
                            type="password"
                            placeholder="Confirm Password"
                            name="confirm_password"
                            onChange={onChange}
                            value={credentials.confirm_password}
                            required
                        />
                        {validateConfirmPassword ?
                            <span className="mt-4 hidden text-sm text-red-500 peer-[&:not(:placeholder-shown):not(:focus)]:block">
                                {validateConfirmPassword}
                            </span>
                            : <></>
                        }
                    </label>
                    <div className="pt-6 fs-5 text-red-500">
                        {errorMssg}
                    </div>
                    <button
                        class={`${loading?'opacity-70':''} mt-5 tracking-wide font-semibold bg-indigo-500 text-gray-100 w-full py-4 rounded-lg hover:bg-indigo-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
                        disabled={loading}
                    >
                        <svg
                            class="w-6 h-6 -ml-2"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        >
                            <path d="M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2" />
                            <circle cx="8.5" cy="7" r="4" />
                            <path d="M20 8v6M23 11h-6" />
                        </svg>
                        <span class="ml-3">
                            {loading?<>Signing Up...<div class="spinner-border ml-2 w-5 h-5" role="status"/></>:<>Sign Up</>}
                        </span>
                    </button>

                </form>
            </div>
        </div>
    )
}
