import React from 'react'

export default function About() {
    return (
        <div className='flex flex-col justify-center items-center text-xl pt-5'>
            <div className='w-[90%] md:w-[70%] p-3 text-justify'>
                <p className='text-4xl font-bold'>About Us</p>
                {/* <p className='font-bold mt-3'>Last updated: October 14, 2023</p> */}


                <p className='mt-4'>Founded in 2007, Annapurna emerged as a pioneering force in the Sweets and vegetarian food
industry. Since our inception, we have been dedicated to crafting exceptional culinary
experiences that celebrate tradition, taste, and quality. With a passion for delivering the finest
sweets and vegetarian delights, we have become a trusted name renowned for our commitment
to excellence.</p>

                <p className='mt-4'>At Annapurna, our mission is to continually surpass milestones while upholding the highest
standards of quality and excellence. We are dedicated to delivering exceptional products and
services, ensuring that every experience with us reflects our unwavering commitment to
excellence and satisfaction.</p>

                <p className='mt-4'>With decades of experience, Annapurna has proudly served both government and private
organizations, establishing us as a trusted partner in the culinary world. Our expertise extends
to the air catering sector, where we have consistently delivered excellence at altitude. Over the
years, we have honed our craft, earning recognition for our unwavering commitment to quality
and service.</p>

                <p className='mt-4'>We hold the value of utmost customer satisfaction above all else. We live by the motto
&#39;Customer Above All,&#39; ensuring that every aspect of our business revolves around exceeding
customer expectations. Our dedication to putting customers first is ingrained in everything we
do, from crafting our culinary creations to providing exceptional service.</p>

                <p className='mt-4'>As a family-run business, everyone at Annapurna is considered part of our extended family. Our
team members are more than just colleagues; they&#39;re like blood relations, united by a shared
commitment to serving our customers with warmth and dedication. This familial bond fosters a
sense of belonging and connection, ensuring that every customer who walks through our doors
receives the same care and attention as if they were family.</p>

                <p className='mt-4'>We&#39;re here to assist you every step of the way. Whether you have questions about our website,
want to learn more about our products, or simply wish to connect, don&#39;t hesitate to reach out.
Our team is dedicated to providing you with the support and assistance you need. Contact us
today, and let us serve you with excellence.</p>

                
            </div>
        </div>
    )
}
