import React from 'react'
import { Link } from 'react-router-dom'
import Carousel from 'react-bootstrap/Carousel';

export default function OfferCard({ offerCard }) {
  if (offerCard)
    return (
      <section className='my-2 pt-5'>
        <div className='mb-3 px-[18px] flex flex-col gap-3 md:flex-row md:justify-between md:items-center md:px-24 md:mb-5'>
          <p className="text-2xl md:text-4xl fw-bold text-[#922636] mb-1 md:mb-5">Special OFFERS Just For You</p>
        </div>
        <div className=''>
        <Carousel fade>
          {offerCard.image_url && offerCard.image_url.map((image, index) => (
            <Carousel.Item key={index}>
              <Link to="/offers">
              <img src={image} className='px-4 md:!px-10 lg:!px-16 xl:!px-20 w-full' alt="Top Banner" />
              </Link>
            </Carousel.Item>
          ))}
        </Carousel>
        </div>


      </section>
    )
}
