import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';

export default function TopBannerCarousel({ images }) {
    return (
        <div className='min-h-[10vh] bg-[#9CA3AF]'>
            <Carousel fade>
                {images.map((image, index) => (
                    <Carousel.Item key={index}>
                        <Link to="/offers">
                        <img src={image} className='w-full' alt="Top Banner" />
                        </Link>
                    </Carousel.Item>
                ))}
            </Carousel>
        </div>
    )
}
