import React from 'react'
import { FaEdit } from "react-icons/fa";
import { FaRegTrashAlt } from "react-icons/fa";
import Button from 'react-bootstrap/Button';
import { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import AddProductForm from './AddProductForm';
import SearchBox from '../../../Utils/SearchBox';
import {filterData} from '../../../Utils/FilterData';
import { deleteFirebaseImage } from '../../../Utils/FirebaseStorage';
import BootstrapToast from '../../BootstrapToast/BootstrapToast';
import axios from 'axios';
import { product_api } from '../../../Utils/constants';
import toast from 'react-hot-toast'
import { useContext } from 'react';
import { AppContext } from '../../../Context/Context';

export default function ProductList() {

  const {jwt} = useContext(AppContext)

  const fetchData = async() => {
    await axios.get(`${product_api}/get_products`)
    .then(function (res) {
      console.log(res.data.data)
      setDatas(res.data.data)
    })
    .catch(() => toast.error("Some Error while fetching data !"))
  }

  const [selectedData, setSelectedData] = useState()

  //Update Modal
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const handleCloseUpdateModal = () => setShowUpdateModal(false);
  const handleCloseUpdateModalFetchData = () => {setShowUpdateModal(false);fetchData()}
  const handleShowUpdateModal = () => setShowUpdateModal(true);

  //Delete Modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const handleCloseDeleteModalFetchData = () => {setShowDeleteModal(false);fetchData()}
  const handleShowDeleteModal = () => setShowDeleteModal(true);

  const [datas, setDatas] = useState([])
  useEffect(() => {
    fetchData()
  },[])

  //Searchbox
  const [searchItem, setSearchItem] = useState('')
  const [filteredData, setFilteredData] = useState([])
  useEffect(() => {
    if (searchItem)
      setFilteredData(filterData(searchItem, datas))
    else
      setFilteredData([])
    // eslint-disable-next-line
  }, [searchItem])

  //Delete a product
  const [loading, setLoading] = useState(false)
  const handleDeleteProduct = async(data) => {
    setLoading(true)
    await axios.post(`${product_api}/delete_product`,
    {id:data.id},
    {headers: {Authorization: `Bearer ${jwt}`}})
    .then(async()=>{
      data.images.map(async(image)=>await deleteFirebaseImage(image,"productImages"))
      toast.success("Deleted Product !")
      handleCloseDeleteModalFetchData()
    })
    .catch((error)=>toast.error("Server Error !"))
    .finally(()=> setLoading(false))
  }

  return (
    <div>
      {loading && <BootstrapToast />}
      <p className='text-2xl font-medium mb-3'>Available Product</p>
      <SearchBox setSearchItem={setSearchItem} />
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Product Name</th>
            <th scope="col">Actual Price</th>
            <th scope="col">Discounted Price</th>
            <th scope="col">Featured</th>
            <th scope="col">Offer</th>
            <th scope="col">Images</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {
            searchItem ?
              filteredData.map((data,index) => (
                <tr key={data.id}>
                  <th scope="row">{index}</th>
                  <td>{data.name}</td>
                  <td>{data.actual_price}</td>
                  <td>{data.discounted_price}</td>
                  <td>{data.is_featured?'Yes':'No'}</td>
                  <td>{data.is_offer?'Yes':'No'}</td>
                  <td>
                    {data.images.map((image,index)=>(
                      <a href={image} target="_blank" rel="noreferrer" className='text-blue' key={index}>{index+1}, </a>
                    ))}
                  </td>
                  <td>
                    <button className='btn btn-outline-primary' onClick={()=>{handleShowUpdateModal();setSelectedData(data)}}><FaEdit /></button>&ensp;
                    <button className='btn btn-outline-danger' onClick={()=>{handleShowDeleteModal();setSelectedData(data)}}><FaRegTrashAlt /></button>
                  </td>
                </tr>
              ))
              :
              datas.map((data,index) => (
                <tr key={data.id}>
                  <th scope="row">{index}</th>
                  <td>{data.name}</td>
                  <td>{data.actual_price}</td>
                  <td>{data.discounted_price}</td>
                  <td>{data.is_featured?'Yes':'No'}</td>
                  <td>{data.is_offer?'Yes':'No'}</td>
                  <td>
                    {data.images.map((image,index)=>(
                      <a href={image} target="_blank" rel="noreferrer" className='text-blue' key={index}>{index+1}, </a>
                    ))}
                  </td>
                  <td>
                    <button className='btn btn-outline-primary' onClick={()=>{handleShowUpdateModal();setSelectedData(data)}}><FaEdit /></button>&ensp;
                    <button className='btn btn-outline-danger' onClick={()=>{handleShowDeleteModal();setSelectedData(data)}}><FaRegTrashAlt /></button>
                  </td>
                </tr>
              ))
          }
        </tbody>
      </table>
      <Modal show={showUpdateModal} onHide={handleCloseUpdateModal} size="lg">
        <Modal.Header>
          <Modal.Title className='w-full flex flex-row justify-between items-center'>
            <p>Update Product</p>
            <button onClick={handleCloseUpdateModal}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
              </svg>
            </button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddProductForm type="edit" data={selectedData} closeModal={handleCloseUpdateModalFetchData} />
        </Modal.Body>
      </Modal>

      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header>
          <Modal.Title className='w-full flex flex-row justify-between items-center'>
            <p>Delete Product</p>
            <button onClick={handleCloseDeleteModal}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
              </svg>
            </button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='font-sans'>Are you sure?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={handleCloseDeleteModal}>
            No
          </Button>
          <Button variant="outline-danger" onClick={()=>handleDeleteProduct(selectedData)} disabled={loading}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  )
}
