import React from 'react'
import { useContext } from 'react'
import { useEffect } from 'react'
import { AppContext } from '../../Context/Context'
import axios from 'axios'
import { order_api } from '../../Utils/constants'
import { useState } from 'react'
import toast from 'react-hot-toast'
import Modal from 'react-bootstrap/Modal'

export default function Orders() {
    const { jwt } = useContext(AppContext)

    const [orderData, setOrderData] = useState([])

    function getDate(timestamp) {
        const date = new Date(Number(timestamp));
        const months = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        const month = months[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();
        return `${month} ${day}, ${year}`;
    }

    function getTime(timestamp) {
        const date = new Date(Number(timestamp));
        const hours = date.getHours() % 12 || 12; // Convert 24-hour to 12-hour format
        const minutes = ('0' + date.getMinutes()).slice(-2);
        const period = date.getHours() < 12 ? 'AM' : 'PM';
        return `${hours}:${minutes} ${period}`;
    }

    const fetchData = () => {
        axios.get(`${order_api}/my_orders`, { headers: { Authorization: `Bearer ${jwt}` } })
            .then((res) => setOrderData(res.data.data))
            .catch((err) => toast.error("Could not fetch data !"))
    }

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //Bill Modal
    const [selectedOrder, setSelectedOrder] = useState('')
    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);

    return (
        <div className='py-3 md:!py-5 px-1 md:!px-20'>
            <p className='text-xl md:text-2xl font-medium mb-3'>My Past Orders</p>
            {
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">DateTime</th>
                            <th scope="col">Products</th>
                            <th scope="col">Bill</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            orderData.length ?
                                orderData.map((data, index) => (
                                    <tr key={data.id} className='align-middle'>
                                        <th scope="row">{index + 1}</th>
                                        <td>
                                            <p>{getDate(data.order_date)}</p>
                                            <p>{getTime(data.order_date)}</p>
                                        </td>
                                        <td>{data.items.map(item => (
                                            <p key={item.product.id}>{item.product.name}</p>
                                        ))}</td>
                                        <td className='text-blue underline cursor-pointer' onClick={() => { setSelectedOrder(data); handleShowModal() }}>View</td>
                                    </tr>
                                ))
                                :
                                <tr>
                                    <td colSpan="4">
                                        <h3 className='text-center'>No Data !</h3>
                                    </td>
                                </tr>
                        }
                    </tbody>
                </table>
            }


            <Modal show={showModal} onHide={handleCloseModal} size='lg'>
                <Modal.Header>
                    <Modal.Title className='w-full flex flex-row justify-between items-center'>
                        <p>Bill</p>
                        <button onClick={handleCloseModal}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedOrder &&
                        <div className='font-sans flex flex-col gap-1 px-1 md:!px-3 py-1'>
                            <p className='text-md md:text-lg text-right'>
                                <span className='font-semibold'>Order Date :</span>&nbsp;
                                {getDate(selectedOrder.order_date)}
                            </p>
                            <br />
                            <p className='text-lg md:text-xl underline font-semibold my-1'>Customer Information</p>
                            <p className='text-md md:text-lg'>
                                <span className='font-semibold'>Name :</span>&nbsp;
                                {selectedOrder.full_name}
                            </p>
                            <p className='text-md md:text-lg'>
                                <span className='font-semibold'>Email :</span>&nbsp;
                                {selectedOrder.user.email}
                            </p>
                            <p className='text-md md:text-lg'>
                                <span className='font-semibold'>Phone :</span>&nbsp;
                                {selectedOrder.phone_number}
                            </p>
                            <p className='text-md md:text-lg'>
                                <span className='font-semibold'>Address :</span>&nbsp;
                                {selectedOrder.address}
                            </p>
                            <p className='text-md md:text-lg'>
                                <span className='font-semibold'>Shipping Address :</span>&nbsp;
                                {selectedOrder.shippingCharge.location}
                            </p>
                            <br />
                            <p className='text-lg md:text-xl underline font-semibold my-1'>Order Summary</p>
                            <table className="table table-striped text-lg">
                                <thead className='text-sm'>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Item</th>
                                        <th scope="col">Price</th>
                                        <th scope="col">Quantity</th>
                                        <th scope="col">Total</th>
                                    </tr>
                                </thead>
                                <tbody className='text-sm'>
                                    {selectedOrder.items.map((item, index) => (
                                        <tr key={item.product.id}>
                                            <th scope="row">{index + 1}</th>
                                            <td>{item.product.name}</td>
                                            <td>{item.product.discounted_price}</td>
                                            <td>{item.quantity}</td>
                                            <td>{item.total_discounted_price}</td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <th className='text-right' colSpan="4">Shipping Charge&nbsp;</th>
                                        <td>{selectedOrder.shippingCharge.charge}</td>
                                    </tr>
                                    <tr>
                                        <th className='text-right' colSpan="4">Total Amount&nbsp;</th>
                                        <th>{selectedOrder.total_discounted_price + selectedOrder.shippingCharge.charge}</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    }
                </Modal.Body>
            </Modal>

        </div>
    )
}
