import React, { useContext, useEffect, useState } from 'react'
import "./AdminPanel.css";
import { useLocation } from 'react-router-dom';
import AddProduct from '../../Components/Admin/Product/AddProduct';
import Dashboard from '../../Components/Admin/Dashboard';
import ProductList from '../../Components/Admin/Product/ProductList';
import AddCategory from '../../Components/Admin/Category/AddCategory';
import CategoryList from '../../Components/Admin/Category/CategoryList';
import HomePageImages from '../../Components/Admin/HomePage/HomePageImages';
import Orders from '../../Components/Admin/Order/Orders';
import SubscribedEmails from '../../Components/Admin/Order/SubscribedEmails';
import ShippingCharges from '../../Components/Admin/Order/ShippingCharges';
import Users from '../../Components/Admin/Users/Users';
import { base_api } from '../../Utils/constants';
import axios from 'axios'
import { AppContext } from '../../Context/Context';
import CheckoutReached from '../../Components/Admin/Order/CheckoutReached';

export default function AdminPanel() {

  // const navigate = useNavigate()
  const { hash } = useLocation()

  const { jwt, user, logout } = useContext(AppContext)
    //Check if user is already logged in and redirect to homepage
    useEffect(() => {
        if (jwt && Object.keys(user).length!==0) {
            if(!user.is_admin){
              window.location.assign("/")
            }
        }
        if(!jwt){
          window.location.assign("/")
        }
    }, [jwt, user])

  //top logo
  const [logo, setLogo] = useState('')
  useEffect(() => {
    axios.get(`${base_api}/home`)
      .then((res) => setLogo(res.data.data.logo))
  }, [])

  const [currentMenu, setCurrentMenu] = useState(<Dashboard />)
  useEffect(() => {
    // console.log(hash)
    if (hash) {

      //Dashboard
      if (hash === "#dashboard") setCurrentMenu(<Dashboard />)
      if (hash === "#users") setCurrentMenu(<Users />)

      //Products
      if (hash === "#addProduct") setCurrentMenu(<AddProduct />)
      if (hash === "#productList") setCurrentMenu(<ProductList />)

      //Category
      if (hash === "#addCategory") setCurrentMenu(<AddCategory />)
      if (hash === "#categoryList") setCurrentMenu(<CategoryList />)

      //Homepage
      if (hash === "#homePage") setCurrentMenu(<HomePageImages />)

      //Order
      if (hash === "#orders") setCurrentMenu(<Orders />)
      if (hash === "#subscribedEmail") setCurrentMenu(<SubscribedEmails />)
      if (hash === "#shippingCharge") setCurrentMenu(<ShippingCharges />)
      if (hash === "#checkoutReached") setCurrentMenu(<CheckoutReached />)
    }
    else setCurrentMenu(<Dashboard />)
  }, [hash])

  const linkClassName =
    "flex items-center px-2 py-1 my-2 w-full text-gray-900 rounded-lg hover:bg-[#f2f2f2] hover:!text-theme-red group";
  // const svgClassName =
  //   "flex-shrink-0 w-5 h-5 fill-[#A6A6A6] transition duration-75 group-hover:fill-lightred dark:group-hover:fill-lightred";

  const active = "!bg-[#f2f2f2] !text-theme-red";
  // const activeIcon = "!fill-lightred";

if(Object.keys(user).length!==0 && user.is_admin)
  return (
    <div>
      <div className='h-24 sticky top-0 py-1 shadow-md bg-white z-50'>
        <div className='w-full h-full px-6 flex flex-row justify-between items-center'>
          <img src={logo} className='h-20' alt="Logo" />
          <div className='flex flex-col items-end gap-2'>
          <p className='font-semibold text-lg font-sans'>Welcome <span className="text-red-600">ADMIN !</span></p>
          <p className='text-sm text-blue pr-2 cursor-pointer' onClick={()=>{logout();window.location.assign("/")}}>Logout</p>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-6 font-sans">
        <div className="col-span-1">
          <div className="left-0 top-24 sticky z-40 w-full h-[calc(100vh-7rem)] shadow-md overflow-y-auto global-scrollbar">
            <ul className="px-6 w-full font-semibold text-[18px]">

              {/* Dashboard */}
              <li>
                <a href="#dashboard" className={`${linkClassName} ${hash === "#dashboard" | "" ? active : ""}`}>
                  <span>Dashboard</span>
                </a>
              </li>
              <li>
                <a href="#users" className={`${linkClassName} ${hash === "#users" ? active : ""}`}>
                  <span>Users</span>
                </a>
              </li>


              {/* Product */}
              <hr className="!mt-5" />
              <span className="text-[12px] px-2 text-[#0d0d0d80]">
                Product
              </span>
              <li>
                <a
                  href="#addProduct" className={`${linkClassName} ${hash === "#addProduct" ? active : ""}`}>
                  <span>Add Product</span>
                </a>
              </li>
              <li>
                <a href="#productList" className={`${linkClassName} ${hash === "#productList" ? active : ""}`}>
                  <span>Product List</span>
                </a>
              </li>


              {/* Category */}
              <hr className="!mt-5" />
              <span className="text-[12px] px-2 text-[#0d0d0d80]">
                Categories
              </span>
              <li>
                <a
                  href="#addCategory" className={`${linkClassName} ${hash === "#addCategory" ? active : ""}`}>
                  <span>Add Category</span>
                </a>
              </li>
              <li>
                <a href="#categoryList" className={`${linkClassName} ${hash === "#categoryList" ? active : ""}`}>
                  <span>Category List</span>
                </a>
              </li>


              {/* Homepage */}
              <hr className="!mt-5" />
              <span className="text-[12px] px-2 text-[#0d0d0d80]">
                Homepage
              </span>
              <li>
                <a
                  href="#homePage" className={`${linkClassName} ${hash === "#homePage" ? active : ""}`}>
                  <span>Home Page</span>
                </a>
              </li>

              {/* Order */}
              <hr className="!mt-5" />
              <span className="text-[12px] px-2 text-[#0d0d0d80]">
                Order
              </span>
              <li>
                <a
                  href="#orders" className={`${linkClassName} ${hash === "#orders" ? active : ""}`}>
                  <span>Orders</span>
                </a>
              </li>
              <li>
                <a
                  href="#shippingCharge" className={`${linkClassName} ${hash === "#shippingCharge" ? active : ""}`}>
                  <span>Shipping Charges</span>
                </a>
              </li>
              <li>
                <a
                  href="#subscribedEmail" className={`${linkClassName} ${hash === "#subscribedEmail" ? active : ""}`}>
                  <span>Subscribed Emails</span>
                </a>
              </li>
              <li>
                <a
                  href="#checkoutReached" className={`${linkClassName} ${hash === "#checkoutReached" ? active : ""}`}>
                  <span>Checkout Reached</span>
                </a>
              </li>

            </ul>
            {/* </div> */}
          </div>
        </div>
        <div className="col-span-5 mt-3 px-10">
          {currentMenu}
        </div>
      </div>
    </div>
  )
}

