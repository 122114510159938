import axios from 'axios';
import React from 'react'
import { account_api } from '../../Utils/constants'
import { useContext } from 'react'
import { AppContext } from '../../Context/Context'
import { useNavigate } from 'react-router-dom';
import ScrollToTop from '../../Components/ScrollToTop'
import toast from 'react-hot-toast';

export default function AccountRegistered({ loginDetails }) {

    const { storeJWT, getUserData } = useContext(AppContext)
    const navigate = useNavigate()

    window.setTimeout(function () {
        axios.post(`${account_api}/login`, {
            email: loginDetails.email,
            password: loginDetails.password
        })
            .then(function (response) {
                if (response.data.success) {
                    storeJWT(response.data.token)
                    getUserData(account_api, response.data.token)
                    toast.success("Logged In !")
                    navigate("/");
                }
            })
            .catch(function (error) {
                // console.log(error)
            })
    }, 2000);

    return (
        <>
            <ScrollToTop />
            <div className='flex flex-col justify-center items-center'>
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
                    <linearGradient id="IMoH7gpu5un5Dx2vID39Ra_pIPl8tqh3igN_gr1" x1="9.858" x2="38.142" y1="9.858" y2="38.142" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#9dffce"></stop><stop offset="1" stop-color="#50d18d"></stop></linearGradient><path fill="url(#IMoH7gpu5un5Dx2vID39Ra_pIPl8tqh3igN_gr1)" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path><linearGradient id="IMoH7gpu5un5Dx2vID39Rb_pIPl8tqh3igN_gr2" x1="13" x2="36" y1="24.793" y2="24.793" gradientUnits="userSpaceOnUse"><stop offset=".824" stop-color="#135d36"></stop><stop offset=".931" stop-color="#125933"></stop><stop offset="1" stop-color="#11522f"></stop></linearGradient><path fill="url(#IMoH7gpu5un5Dx2vID39Rb_pIPl8tqh3igN_gr2)" d="M21.293,32.707l-8-8c-0.391-0.391-0.391-1.024,0-1.414l1.414-1.414	c0.391-0.391,1.024-0.391,1.414,0L22,27.758l10.879-10.879c0.391-0.391,1.024-0.391,1.414,0l1.414,1.414	c0.391,0.391,0.391,1.024,0,1.414l-13,13C22.317,33.098,21.683,33.098,21.293,32.707z"></path>
                </svg>
                <p className='fs-3 text-success'>Account Successfully Created</p>
                <p className='fs-4 mt-5'>Redirecting...</p>

            </div>
        </>
    )
}
