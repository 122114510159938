import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import axios from 'axios'
import { base_api } from '../../../Utils/constants'
import toast from 'react-hot-toast'
import UpdateHomePageImages from './UpdateHomePageImages'
import Modal from 'react-bootstrap/Modal';


export default function HomePageImages() {

    const [data, setData] = useState()

    const fetchData = async () => {
        await axios.get(`${base_api}/home`)
            .then(function (res) {
                setData(res.data.data)
            })
            .catch(() => toast.error("Some Error while fetching data !"))
    }

    useEffect(() => {
        fetchData()
    }, [])

    //Update Modal
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const handleCloseUpdateModal = () => setShowUpdateModal(false);
    const handleCloseUpdateModalFetchData = () => { setShowUpdateModal(false); fetchData() }
    const handleShowUpdateModal = () => setShowUpdateModal(true);

    return (
        <div>

            <div className='w-full flex justify-end mb-10'>
                <button className='btn btn-primary' onClick={handleShowUpdateModal}>Update</button>
            </div>

            {/* Carousel Banner Image */}
            <div className='flex flex-col gap-2'>
                <p className='text-2xl font-medium'>Carousel Banner Images</p>
                <div className='flex flex-wrap items-center justify-center gap-3'>
                    {data &&
                        data.carousel_images ?
                        data.carousel_images.map((image, index) => (
                            <img src={image} key={index} className='max-w-[500px]' alt="Carousel" />
                        ))
                        :
                        <p>No carousel image uploaded !</p>
                    }
                </div>
            </div>

            {/* Offer Banner Image */}
            <hr className='my-5' />
            <div className='flex flex-col gap-2'>
                <p className='text-2xl font-medium'>Offer Banner Images</p>
                <div className='flex flex-wrap items-center justify-center gap-3'>
                {data && data.offer_image &&
                        data.offer_image.image_url ?
                        data.offer_image.image_url.map((image, index) => (
                            <img src={image} key={index} className='max-w-[500px]' alt="Offer" />
                        ))
                        :
                        <p>No offer image uploaded !</p>
                    }
                </div>
            </div>
            {/* Top Logo Image */}
            <hr className='my-5' />
            <div className='flex flex-col gap-2'>
                <p className='text-2xl font-medium'>Logo</p>
                <div className='flex flex-col items-center'>
                    {data && data.logo && <img src={data.logo} className='max-w-[500px]' alt="Offer" />}
                </div>
            </div>


            <Modal show={showUpdateModal} onHide={handleCloseUpdateModal} size="lg">
                <Modal.Header>
                    <Modal.Title className='w-full flex flex-row justify-between items-center'>
                        <p>Update Homepage Images</p>
                        <button onClick={handleCloseUpdateModal}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <UpdateHomePageImages data={data} closeModal={handleCloseUpdateModalFetchData} />
                </Modal.Body>
            </Modal>
        </div>
    )
}
