import React from 'react'
import CheckoutForm from './CheckoutForm'
import { useState } from 'react'
import CheckoutComplete from './CheckoutComplete'
import { useContext } from 'react'
import { AppContext } from '../../Context/Context'
import axios from 'axios'
import { order_api } from '../../Utils/constants'
import { useEffect } from 'react'

export default function Checkout() {

    const { cartItems, jwt } = useContext(AppContext);

    const products = cartItems.map(cartItem => { return cartItem.id })
    const [flag, setFlag] = useState(0)

    useEffect(() => {
        // console.log("done")
        if (!flag && products.length) {
            const delayDebounceFn = setTimeout(() => {
                axios.post(`${order_api}/checkout_reached`, { "product_ids": products }, { headers: { Authorization: `Bearer ${jwt}` } })
                    .then(function (response) {
                        console.log(response)
                        setFlag(1)
                    })
            }, 3000)
            return () => clearTimeout(delayDebounceFn)
        }
    }, [jwt, products, flag])


    const [step, setStep] = useState(1)
    return (
        <div className="container py-5 h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
                <div className="col-12">
                    <div className="card" style={{ borderRadius: '15px' }}>
                        <div className="card-body p-0">
                            {step === 1 ? <CheckoutForm setStep={setStep} /> : <CheckoutComplete />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
