import React from 'react'
import { useState } from 'react'
import { account_api } from '../../Utils/constants'
import axios from 'axios'

export default function EmailForm({setStep}) {

    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [errMssg, setErrMssg] = useState('')

    const onChange = (e) => {
        setEmail(e.target.value)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if(email===''){
            setErrMssg("Type your email!")
            return
        }
        setLoading(true)
        axios.post(`${account_api}/forgot_password`,{"email": email})
        .then(function(response){
            if(response.data.success){
                setLoading(false)
                setStep(2)
            }
            else{
                setErrMssg("Error Occurred !")
            }
            setLoading(false)
        })
        .catch(function(error){
            if(error.response.status === 401) {
                setErrMssg("No user registered with provided email!")
            }
            else{
                setErrMssg("Internal Server Error!")
            }
            setLoading(false)
        })
    }

    return (
        <div class="w-full h-100">
            <h1 class="text-xl md:text-2xl font-bold leading-tight mt-4 mb-2">Reset Password</h1>
            <hr class="my-6 border-gray-300 w-full" />

            <form class="mt-6" onSubmit={onSubmit}>
                <div>
                    <label class="block text-gray-700">Email Address</label>
                    <input type="email" name="email" placeholder="Enter Email Address" class="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none" autofocus autocomplete value={email} onChange={onChange} required />
                </div>

                <div class="text-center mt-2">
                    <p className="text-lg font-semibold text-red-500">{errMssg}</p>
                </div>

                {loading ?
                    <button
                        type="submit"
                        class="opacity-60 w-full block bg-indigo-500 hover:bg-indigo-400 focus:bg-indigo-400 text-white font-semibold rounded-lg px-4 py-3 mt-6"
                        disabled
                    >Next
                        <div class="spinner-border ml-2 w-5 h-5" role="status" />
                    </button>
                    :
                    <button
                        type="submit"
                        class="w-full block bg-indigo-500 hover:bg-indigo-400 focus:bg-indigo-400 text-white font-semibold rounded-lg px-4 py-3 mt-6"
                    >Next</button>
                }
            </form>

            <hr class="my-6 border-gray-300 w-full" />

            


        </div>
    )
}
