import axios from "axios";
import { useRef } from "react";
import { useEffect } from "react";
import React from "react";
import { account_api } from "../Utils/constants"
import { useNavigate } from "react-router-dom";
import { AppContext } from "../Context/Context";
import { useContext } from "react";
import toast from "react-hot-toast";

function GoogleCallback() {

  //URL
  const called = useRef(false);
  // console.log(window.location.search)

  //navigate after login
  const navigate = useNavigate()

  //Store jwt key function
  const { storeJWT, jwt, getUserData } = useContext(AppContext)
  //Check if user is already logged in and redirect to homepage
  useEffect(() => {
    if (jwt) {
      navigate("/")
    }
  }, [jwt, navigate])

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       if (called.current) return; // prevent rerender caused by StrictMode
  //       called.current = true;
  //       console.log(`${account_api}/google_login_callback${window.location.search}`)
  //       const response = await axios.get(`${account_api}/google_login_callback${window.location.search}`);
  //       console.log(response)
  //       storeJWT(response.data.token)
  //       getUserData(account_api, response.data.token)
  //       toast.success("Logged In !")
  //       // navigate('/');
  //     } catch (err) {
  //       console.error(err);
  //       toast.error("Some Error ! Try again later.")
  //       // navigate('/login');
  //     }
  //   })();
  // }, [getUserData, navigate, storeJWT])
  useEffect(()=>{
    if(called.current) return;
    called.current = true;
    axios.get(`${account_api}/google_login_callback${window.location.search}`)
    .then((response)=>{
      console.log(response)
      storeJWT(response.data.token)
      getUserData(account_api, response.data.token)
      toast.success("Logged In !")
    })
    .catch((e)=>{
      console.log(e)
      toast.error("Some Error! Try again later.")
    })
  },[getUserData, navigate, storeJWT])
  return <div className="p-5 flex flex-col justify-center items-center font-md">Redirecting...</div>
};

export default GoogleCallback