import React, { useState } from 'react'
import { AppContext } from '../../Context/Context'
import { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BsCart4 } from 'react-icons/bs'
import { TbTruckDelivery } from 'react-icons/tb'
import { useEffect } from 'react';
import { FaStarOfLife } from 'react-icons/fa'
import axios from 'axios';
import { order_api } from '../../Utils/constants';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import './Checkout.css'
export default function CheckoutForm({ setStep }) {

    const { cartItems, total_amount, jwt, emptyCart, user } = useContext(AppContext);
    const [errMssg, setErrMssg] = useState('')
    const [loading, setLoading] = useState(false)
    const [shippingRate, setShippingRate] = useState(0)

    //Check if user is authorized otherwise redirect to homepage
    const navigate = useNavigate()
    useEffect(() => {
        if (!jwt) {
            navigate("/")
        }
    }, [jwt, navigate])

    //Fetch Shipping Fees and Location
    const [shippingLocation, setShippingLocation] = useState('')
    useEffect(() => {
        axios.get(`${order_api}/shipping_charge`, { headers: { Authorization: `Bearer ${jwt}` } })
            .then(function (response) {
                // console.log(response)
                if (response.data.success) {
                    setShippingLocation(response.data.data)
                    setShippingRate(response.data.data[0].charge)
                    setCredentials({ ...credentials, shipping: response.data.data[0].id })
                }
            })
            .catch(function (error) {
                console.log(error)
            })
        // eslint-disable-next-line
    }, [])

    //Checkout form Credentials
    const [credentials, setCredentials] = useState({ firstName: '', lastName: '', email: user.email, phone: '', address: '', shipping: '' })
    const onChange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value })
    }
    // console.log(credentials.shipping)
    //Set shipping rate
    useEffect(() => {
        if (shippingLocation) {
            shippingLocation.map(location => (
                (location.id === credentials.shipping) ? setShippingRate(location.charge) : {}
            ))
        }
        return;
    }, [shippingLocation, credentials.shipping])

    //Handle Submit on Checkout
    const onSubmit = (e) => {
        e.preventDefault()
        if (credentials.firstName === '' || credentials.lastName === '' || credentials.phone === '' || credentials.address === '' || credentials.shipping === '') {
            setErrMssg("All fields are required!")
            return
        }
        //Phone validation
        // if (credentials.phone.slice(0, 2) !== '97' && credentials.phone.slice(0, 2) !== '98') {
        //     setErrMssg("Not a valid phone number!")
        //     return
        // }
        // if (credentials.phone.length !== 10) {
        //     setErrMssg("Not a valid phone number!")
        //     return
        // }
        // console.log(credentials.shipping)
        setLoading(true)
        var products = []
        cartItems.map(item => (
            products.push({ "product_id": item.id, "quantity": String(item.quantity) })
        ))
        // products.push({ "product_id": "c77f4c69-81ea-4aab-bf87-b3bd0375ea4c", "quantity": 5 })
        // products = JSON.stringify(products)
        console.log(products)
        axios.post(`${order_api}`, {
            "user_id": user.user_id,
            "phone_number": credentials.phone,
            "full_name": `${credentials.firstName + " " + credentials.lastName}`,
            "address": credentials.address,
            "shipping_charge_id": credentials.shipping,
            "items": products
        }, {
            headers: {
                Authorization: `Bearer ${jwt}`
            }
        })
            .then(function (response) {
                console.log(response)
                if (response.data.success) {
                    setStep(2)
                    emptyCart()
                    setLoading(false)
                }
            })
            .catch(function (error) {
                console.log(error)
                setErrMssg("Couldnot place order! Try again later.")
                setLoading(false)
            })
    }

    //If cartItems is empty display cartItems is empty
    if (cartItems.length < 1) {
        return <section className='flex flex-col justify-center items-center h-screen gap-6 pb-8'>
            <BsCart4 className=' text-[15rem]' />
            <div className='fs-1'>
                Your cart is empty!
            </div>
            <Link to='/products'>
                <button className='buy-btn fs-4 p-2'>
                    Shop Now!
                </button>
            </Link>
        </section>
    }


    const SummaryView = () => {
        return (
            <div className="col-lg-5 -mt-14 lg:mt-0 bg-grey">
                <div className="p-5">
                    <h3 className="fw-bold mb-5 mt-2 pt-1 fs-2">Summary</h3>
                    <hr className="my-4" />

                    <div className="d-flex flex-col justify-content-between mb-4">
                        {cartItems.map(item => (
                            <div key={item.id}>
                                <div className='grid grid-cols-5'>
                                    <p className='col-span-4'>{item.title} <span className='font-bold font-sans'>({item.quantity})</span></p>
                                    <h5 className='text-right'>Rs. {item.price * item.quantity}</h5>
                                </div>
                                <hr className='my-2' />
                            </div>
                        ))}
                    </div>

                    {/* <div className="d-flex justify-content-between mb-4">
                    <h5 className="text-uppercase">Tax(15%)</h5>
                    <h5>€ 132.00</h5>
                </div> */}
                    <div className="d-flex justify-content-between mb-4">
                        <h5 className="text-uppercase">Shipping</h5>
                        <h5>Rs. {shippingRate}</h5>
                    </div>
                    {/* <h5 className="text-uppercase mb-3">Promo code</h5>
                <div className="mb-5">
                    <div className="form-outline">
                        <input type="text" id="form3Examplea2" className="form-control form-control-lg" />
                        <label className="form-label" for="form3Examplea2">Enter your code</label>
                    </div>
                </div> */}
                    <hr className="my-4" />
                    <div className="d-flex justify-content-between mb-5">
                        <h5 className="text-uppercase fw-bold">Total price</h5>
                        <h5 className='fw-bold'>Rs. {Math.ceil(total_amount + shippingRate)}</h5>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <form onSubmit={onSubmit}>
            <div className="row g-0">
                <div className="col-lg-7">
                    <div className="p-5">
                        <div className="d-flex justify-content-between align-items-center mb-5">
                            <h1 className="fw-bold fs-2 mb-0 text-black">Checkout</h1>
                            <h6 className="mb-0 text-muted">{cartItems.length} items</h6>
                        </div>
                        <hr className="my-4" />


                        <div class="col-md-8 order-md-1">
                            <h4 class="mb-3 fw-bold">Billing address</h4>
                            <div class="row">
                                <div class="col-md-6 mb-3">
                                    <label for="firstName" className='flex flex-row gap-1'>First name<FaStarOfLife className='w-2 h-2 text-red-700' /></label>
                                    <input type="text" class="form-control" name="firstName" value={credentials.firstName} onChange={onChange} required />
                                    <div class="invalid-feedback">
                                        Valid first name is required.
                                    </div>
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label for="firstName" className='flex flex-row gap-1'>Last name<FaStarOfLife className='w-2 h-2 text-red-700' /></label>
                                    <input type="text" class="form-control" name="lastName" value={credentials.lastName} onChange={onChange} required />
                                    <div class="invalid-feedback">
                                        Valid last name is required.
                                    </div>
                                </div>
                            </div>

                            <div class="mb-3">
                                <label for="email">Email</label>
                                <input type="email" class="form-control" name="email" placeholder="you@example.com" value={credentials.email} onChange={onChange} />
                                <div class="invalid-feedback">
                                    Please enter a valid email address for shipping updates.
                                </div>
                            </div>

                            <div class="mb-3">
                                <label for="firstName" className='flex flex-row gap-1 mb-1'>Phone Number<FaStarOfLife className='w-2 h-2 text-red-700' /></label>
                                <PhoneInput
                                    className='font-sans'
                                    style={{"--react-international-phone-font-size":"17px"}}
                                    defaultCountry="np"
                                    disableDialCodeAndPrefix={true}
                                    showDisabledDialCodeAndPrefix={true}
                                    value={credentials.phone}
                                    onChange={(phone) => setCredentials({...credentials, phone: phone})}
                                    required
                                />
                            </div>


                            <div class="mb-3">
                                <label for="firstName" className='flex flex-row gap-1'>Full Shipping Address<FaStarOfLife className='w-2 h-2 text-red-700' /></label>
                                <input type="text" class="form-control" name="address" placeholder="Patandhoka Lalitpur" value={credentials.address} onChange={onChange} required />
                                <div class="invalid-feedback">
                                    Please enter your shipping address.
                                </div>
                            </div>



                            <hr class="mb-4" />
                            <h4 class="mb-3">Payment</h4>
                            <div class="mb-3">
                                <label for="firstName" className='flex flex-row gap-1'>Shipping Fee<FaStarOfLife className='w-2 h-2 text-red-700' /></label>
                                <select className='w-full border-2 h-10' name="shipping" onChange={onChange} required>
                                    {shippingLocation ? shippingLocation.map(location => (
                                        <option key={location.location} value={location.id}>{location.location} @ Rs.{location.charge}</option>
                                    ))
                                        :
                                        <></>
                                    }
                                </select>

                            </div>
                            <div className='flex flex-row justify-center items-center gap-2'>
                                <TbTruckDelivery className='w-16 h-16' />
                                <p className='fs-5'>Cash on Delivery</p>
                            </div>

                            <hr class="my-3" />
                            <p className='text-danger fs-5 mb-2'>{errMssg}</p>
                            <div className='hidden lg:block'>
                                {/* {credentials.firstName}
                                {credentials.lastName}
                                {credentials.address}
                                {credentials.phone}
                                {credentials.email}
                                {credentials.shipping} */}
                                <button class="btn btn-primary btn-lg" disabled={loading}>Confirm Checkout{loading ? <div class="spinner-border ml-2 w-5 h-5" role="status" /> : <></>}</button>
                            </div>
                        </div>


                        <div className="pt-5 hidden lg:block">
                            <h6 className="mb-0"><Link to="/products" className="text-body"><i
                                className="fas fa-long-arrow-alt-left me-2"></i>Back to shop</Link></h6>
                        </div>
                    </div>
                </div>

                <SummaryView />
                <div className='lg:hidden -pt-5 mb-5 flex flex-row justify-center'>
                <button class="btn btn-primary btn-lg" disabled={loading}>Confirm Checkout{loading ? <div class="spinner-border ml-2 w-5 h-5" role="status" /> : <></>}</button>
                </div>

            </div>
        </form>

    )
}
