import React from 'react'
import { Link } from 'react-router-dom'
import { BiSolidErrorAlt } from 'react-icons/bi'

export default function Error() {
    return (
        <section className='flex flex-col justify-center items-center h-screen gap-6 pb-8'>
            <BiSolidErrorAlt className=' text-[15rem]'/>
            <div className='fs-1'>
                The page you requested does not exist !
            </div>
            <Link to='/'>
                <button className='buy-btn fs-4 p-3'>
                    Go Back
                </button>
            </Link>
        </section>
    )
}
