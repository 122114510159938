import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Home from './Pages/Home/Home'
import Products from './Pages/Products/Products';
import Cart from './Pages/Cart/Cart'
import ProdcutDetails from './Pages/ProductDetails/ProdcutDetails';
import Login from './Pages/Login/Login';
import Register from './Pages/Register/Register';
import ScrollToTop from './Components/ScrollToTop'
import NavigationBar from './Components/NavigationBar/NavigationBar';
import Footer from './Components/Footer/Footer';
import Error from './Pages/Error/Error';
import Wishlist from './Pages/Wishlist/Wishlist'
import MobileNavbar from './Components/NavigationBar/MobileNavbar';
import Checkout from './Pages/Checkout/Checkout';
import ResetPassword from './Pages/ResetPassword/ResetPassword';
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy';
import GoogleCallback from './Components/GoogleCallback';
import PastOrders from './Pages/PastOrders/PastOrders'
import AdminPanel from './Pages/Admin/AdminPanel';
import { useEffect, useState } from 'react';
import OfferProducts from './Pages/OfferProducts/OfferProducts';
import About from './Pages/About/About';
import DeleteAccount from './Pages/DeleteAccount/DeleteAccount';

function App() {

  const [isAdmin, setIsAdmin] = useState(false)
  // Check if the current route matches /admin
  const isOnAdminRoute = window.location.pathname.startsWith('/admin');
  // Redirect to the home page if the user tries to access /admin directly
  useEffect(()=>{
    if (isOnAdminRoute) {
      setIsAdmin(true)
    }
    else
      setIsAdmin(false)
  },[isOnAdminRoute, setIsAdmin])

  return (
    <Router>
      <ScrollToTop />
      {!isAdmin && <NavigationBar />}
      <Routes>
        <Route index element={<Home />} />
        <Route path="/products" element={<Products />} />
        {/* <Route path="/products/category/:productCategory" element={<CategoryProducts />} /> */}
        <Route path="/cart" element={<Cart />} />
        <Route path="/wishlist" element={<Wishlist />} />
        <Route path="/products/:productSlug" element={<ProdcutDetails />} />
        <Route path="/login" element={<Login />} />
        <Route path="/login/:param" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/*" element={<Error />} />
        <Route path="/terms" element={<PrivacyPolicy />} />
        <Route path="/about" element={<About />} />
        <Route path="/auth/callback" element={<GoogleCallback />} />
        <Route path="/admin" element={<AdminPanel />} />
        <Route path="/offers" element={<OfferProducts />} />
        <Route path="/past_orders" element={<PastOrders />} />
        <Route path="/user/deleteaccount" element={<DeleteAccount />} />
        
      </Routes>
      {!isAdmin && <Footer />}
      {!isAdmin && <MobileNavbar/>}
    </Router>
  );
}

export default App;
