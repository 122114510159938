import React from 'react'
import Rating from '../Rating/Rating'
import { AiFillDelete } from 'react-icons/ai'
import { useContext } from 'react';
import { AppContext } from '../../Context/Context';
import { Link } from 'react-router-dom';

export default function WishlistItem({ item }) {
    const { removeWishlistProduct } = useContext(AppContext);

    return (
        <div className="p-3">
            <div className='grid lg:grid-cols-5'>
                <div className='flex flex-col items-center justify-center'>
                    <Link to={`/products/${item.title.replace(/[ ]/g, "-")}`}>
                        <img src={item.images[0]} alt="Wishlist Product" className="img-fluid rounded-3 max-w-[200px]" />
                    </Link>
                </div>
                <div className='lg:col-span-2 flex flex-col text-center justify-center'>
                    <Link to={`/products/${item.title.replace(/[ ]/g, "-")}`}><p className='fs-2'>{item.title}</p></Link>
                    <Rating rating={item.rating} />
                </div>
                <div className='flex flex-col items-center justify-center'><p className='text-center fs-4 '>Rs. {item.price}</p></div>
                <div className='flex flex-col items-center justify-center'><AiFillDelete className='fs-3 fill-red-500 cursor-pointer' onClick={() => { removeWishlistProduct(item.id) }} /></div>
            </div>
            <hr className='my-4' />
        </div>
    )
}
