import React from 'react'
import './Loader.css'

export default function Loader() {
    return (
        <div className='flex justify-center items-center h-96'>
            <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
    )
}
